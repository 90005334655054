import {
	Component,
	OnInit,
	Input,
	OnChanges,
	SimpleChanges,
	ViewChild,
	AfterViewInit,
	OnDestroy,
	ElementRef,
	ViewChildren,
	QueryList,
	Output,
	EventEmitter,
} from "@angular/core";
import { AxonComponent } from "../../axon.component";
import { Image, DataResponse, Customer, AbstractMomokashUser } from "../../dto/dtos";
import { ImageService } from "../../services/images/images.service";
import { ServerError } from "../../utils/server-errors";
import { NgScrollbar } from "ngx-scrollbar";
import { OriginalImageDialogComponent } from "../original-image-dialog/original-image-dialog.component";
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { DragScrollComponent } from "ngx-drag-scroll";
import { Subscription, Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { Notifier } from "../../utils/notifier";
import { AxonSpinnerComponent } from "../spinner/spinner.component";
import { CustomerService } from "../../services/customers/customer.service";

/**
 * Class to image data.
 * Used for cache hit/miss - as a hit might not yet have image data.
 * Should the image not have been loaded within 30 seconds, this entry will expire allowing the browser to potencially refetch the image.
 */
class ImageCacheValue {
	data: string;
	createdAt: Date;
	constructor() {
		this.createdAt = new Date();
	}
	get isExpired() {
		if (this.data !== null) {
			return false;
		}
		return this.createdAt.getTime() + 1000 * 30 < new Date().getTime();
	}
}

const SCROLL_AMOUNT = 120;
@Component({
	selector: "app-image-viewer",
	templateUrl: "./image-viewer.component.html",
	styleUrls: ["./image-viewer.component.scss"],
})
export class ImageViewerComponent extends AxonComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() customer: Customer;
	@Input() momokashUser: AbstractMomokashUser;
	@Input() images: Image[];
	@Input() axonId: number;
	@Input() pinref: number;
	@Input() editable = false;

	@Output() onRefreshCustomer = new EventEmitter<Customer>();

	scrolled: boolean;
	scrollOnClicked: boolean;
	scrollPosition = 0;

	scrollSubscription: Subscription;

	@ViewChild(NgScrollbar) scrollRef: NgScrollbar;
	@ViewChild("multiscan", { read: DragScrollComponent }) multiscan: DragScrollComponent;
	@ViewChild("imageDisplayDiv") imageDisplayDiv: ElementRef;
	@ViewChild("imageDisplayCaptionDiv") imageDisplayCaptionDiv: ElementRef;

	scrollMax = 0;
	scrollDirection: "left" | "right" | "none" = "none";
	leftDisabled = true;
	rightDisabled = true;
	shown: "native" | "hover" | "always" = "native";

	isLoadingResults = false;
	currentImage: Image;
	displayImage: Image;
	currentImageIndex = 0;
	currentMultiscanIndex = 0;
	imageCache: Map<string, ImageCacheValue> = new Map();

	viewImageMaxDisplayIncrements: number = 50;
	viewImageMaxDisplayRatio: number = 0.9;
	viewImageMaxDisplayWidth: number = 0;
	viewImageMaxDisplayHeight: number = 0;
	multiscanImages?: Array<Image>;

	constructor(
		private imageService: ImageService,
		private modalService: NgbModal,
		private notifier: Notifier,
		private customerService: CustomerService
	) {
		super();
	}

	setShown() {
		if (this.shown === "native") {
			this.shown = "hover";
		} else if (this.shown === "hover") {
			this.shown = "always";
		} else {
			this.shown = "native";
		}
	}

	ngOnInit() {
		/* On init, show the first image in the list */
		console.log("Fetching and showing default image");
		// sort images by type ascending
		console.log("Dickie: Images", this.images);
		this.images = this.images.filter((img) => img.type.toLowerCase().indexOf("template") < 0);
		console.log("Dickie: Images: Filtered", this.images);
		this.images.sort((a, b) => a.type.localeCompare(b.type));
		if (this.images && this.images[0]) {
			this.selectImage(this.images[0], 0);
		}
	}

	/**
	 * Fetches an image using the axon ID and given imageId from the server for displaying,
	 * or from the originalImages array, if it was previously downloaded
	 * in the viewing image section
	 * @param imageId
	 */
	selectImage(image: Image, imageIndex: number) {
		this.currentImage = image;
		this.displayImage = image;
		this.currentImageIndex = imageIndex;
		const multiscanImages = this.currentImage.multiscanImages.filter((item) => item.id !== this.currentImage.id);
		if (this.currentImage.multiscanImages.length > 0) {
			multiscanImages.unshift(this.currentImage);
		}
		this.multiscanImages = multiscanImages;
		this.disableArrows();
	}

	getDisplayImage(scaleToFit: boolean): string | SafeUrl {
		const maxDisplayWidth = scaleToFit === true ? this.calculateMaxDisplayWidth() : null;
		const maxDisplayHeight = scaleToFit === true ? this.calculateMaxDisplayHeight() : null;
		return this.getImageUrl(this.displayImage, maxDisplayWidth, maxDisplayHeight);
	}
	/**
	 * Fetch the image with id and dimensions.
	 */
	private getImageUrl(image: Image, maxDisplayWidth: number, maxDisplayHeight: number): string | SafeUrl {
		if (!image) {
			return;
		}

		if (image.newImageUrl) {
			return image.newImageUrl;
		}

		if (image.isPlaceholder) {
			return image.safeImageUrl;
		}

		let out = image.imageUrl;
		let seperator = image.imageUrl.indexOf("?") === -1 ? "?" : "&";
		if (maxDisplayWidth != null) {
			out += `${seperator}max-width=${maxDisplayWidth}`;
			seperator = "&";
		}
		if (maxDisplayHeight != null) {
			out += `${seperator}max-height=${maxDisplayHeight}`;
			seperator = "&";
		}
		if (image.rotation != null) {
			out += `${seperator}rotation=${image.rotation}`;
			seperator = "&";
		}

		return out;
		// const key = maxDisplayWidth + ":" + maxDisplayHeight + ":" + image.id + ":" + image.hashCode;
		// const out = this.imageCache.get(key);
		// if (out !== undefined && out !== null) {
		// 	if (!out.isExpired) {
		// 		console.log("Image Cache Lookup.", key, "HIT");
		// 		return out.data;
		// 	}
		// 	console.log("Image Cache Lookup.", key, "Expired");
		// } else {
		// 	this.imageCache.set(key, new ImageCacheValue());
		// }

		// console.log("Image Cache Lookup.", key, out);

		// this.isLoadingResults = true;
		// const fetchMultiscanThumbnails: boolean = image.multiscan && !image.multiscanImages;

		// const queryWithAxonId = () => this.imageService.getImage(this.axonId, image.id, fetchMultiscanThumbnails, maxDisplayWidth, maxDisplayHeight);
		// const queryWithPinRef = () => this.imageService.getHistoricImageByPinref(this.pinref, image.id, fetchMultiscanThumbnails, maxDisplayWidth, maxDisplayHeight);
		// const observable = (this.axonId) ? queryWithAxonId() : queryWithPinRef();

		// this.isLoadingResults = true;
		// observable.pipe(tap(data => console.log("Loaded data", data))).subscribe(result => {
		// 	const value = new ImageCacheValue();
		// 	value.data = <string>this.sanitise(result.data.imageUrl);
		// 	if (result.data.multiscanImages !== undefined) {
		// 		this.multiscanImages = result.data.multiscanImages;
		// 		this.currentImage.multiscanImages = this.multiscanImages;
		// 	}
		// 	this.imageCache.set(key, value);
		// 	this.isLoadingResults = false;
		// });
		// return null;
	}
	/**
	 * Get the width of the image display div.
	 */
	private calculateMaxDisplayWidth(): number {
		if (!this.imageDisplayDiv) {
			return undefined;
		}
		// return this.calculateMaxDisplaySize(this.imageDisplayDiv.nativeElement.offsetWidth);

		const truncatedDivWidth =
			Math.round(this.imageDisplayDiv.nativeElement.offsetWidth / this.viewImageMaxDisplayIncrements) * this.viewImageMaxDisplayIncrements;

		//Display area is smaller than current image - then reduce current image
		if (truncatedDivWidth < this.viewImageMaxDisplayWidth) {
			this.viewImageMaxDisplayWidth = truncatedDivWidth;
			return Math.round(this.viewImageMaxDisplayWidth * this.viewImageMaxDisplayRatio);
		}

		//Display area is larger than current image - then increase current image
		if (truncatedDivWidth > this.viewImageMaxDisplayWidth + this.viewImageMaxDisplayIncrements) {
			this.viewImageMaxDisplayWidth = truncatedDivWidth;
			return Math.round(this.viewImageMaxDisplayWidth * this.viewImageMaxDisplayRatio);
		}
		//Keep current image size unchanged
		return Math.round(this.viewImageMaxDisplayWidth * this.viewImageMaxDisplayRatio);
	}

	/**
	 * Get the height of the image display div.
	 */
	private calculateMaxDisplayHeight(): number {
		if (!this.imageDisplayDiv) {
			return undefined;
		}
		let size = this.imageDisplayDiv.nativeElement.offsetHeight;
		if (this.imageDisplayCaptionDiv) {
			size += this.imageDisplayCaptionDiv.nativeElement.offsetHeight;
		}
		// return this.calculateMaxDisplaySize(size);

		const truncatedDivHeight = Math.round(size / this.viewImageMaxDisplayIncrements) * this.viewImageMaxDisplayIncrements;
		//Display area is smaller than current image - then reduce current image
		if (truncatedDivHeight < this.viewImageMaxDisplayHeight) {
			this.viewImageMaxDisplayHeight = truncatedDivHeight;
			return Math.round(this.viewImageMaxDisplayHeight * this.viewImageMaxDisplayRatio);
		}

		//Display area is larger than current image - then increase current image
		if (truncatedDivHeight > this.viewImageMaxDisplayHeight + this.viewImageMaxDisplayIncrements) {
			this.viewImageMaxDisplayHeight = truncatedDivHeight;
			return Math.round(this.viewImageMaxDisplayHeight * this.viewImageMaxDisplayRatio);
		}
		//Keep current image size unchanged
		return Math.round(this.viewImageMaxDisplayHeight * this.viewImageMaxDisplayRatio);
	}
	//=======================================================================================================================================

	ngAfterViewInit() {
		if (this.scrollRef) {
			this.setScrollMax();
			if (this.scrollPosition < this.scrollMax) {
				this.rightDisabled = false;
			}
			this.scrollSubscription = this.scrollRef.scrollable.elementScrolled().subscribe((e) => {
				/*
								Indicate that scrolling has occurred - this is needed to determine if the scroll has
								exceeded min and max, so the position can be adjusted accordingly
								*/
				this.scrolled = true;

				let adjustedScrollMax = false;

				if (!this.scrollOnClicked) {
					/*
										The user is scrolling using the bar - need to check if position has exceeded min or max
										and disable arrows / adjust positions
										*/
					this.scrollPosition = this.scrollRef.view.scrollLeft;
					if (this.scrollPosition <= 0) {
						this.scrollPosition = 0;
					} else if (this.scrollPosition >= this.scrollMax) {
						/*
												scrollMax is calculated via setScrollMax() - however this is not accurate, so if the user
												has scrolled greater than the max, then re-adjust max to current position.
												*/
						this.scrollMax = this.scrollPosition;

						adjustedScrollMax = true;
					}
				}
			});
		}
		this.initResizeListener();
	}

	/**
	 * Sets the maximum scroll max if it's not set - note: scroll max is actually
	 * less than the allowed scroll. This is probably because I have not calculated it properly
	 */
	private setScrollMax() {
		if (this.scrollMax === 0) {
			/* Add +10 as a little extra buffer */
			this.scrollMax = this.scrollRef.view.scrollWidth - this.scrollRef.view.offsetWidth + 10;
			// console.log('scroll max: ' + this.scrollMax);
		}
	}

	/**
	 * Handles left arrow click - scrolls to the left, if the arrow is not disabled
	 */
	scrollLeft() {
		if (this.scrollPosition > 0) {
			this.scrollOnClicked = true;
			this.scrollDirection = "left";
			this.scrollPosition = this.scrollPosition - SCROLL_AMOUNT;
			this.scroll("left", true);
		} else {
			this.scroll("left", false);
		}
	}

	/**
	 * Handles right arrow click - scrolls to the right, if the arrow is not disabled
	 */
	scrollRight() {
		if (this.scrollPosition < this.scrollMax) {
			this.scrollOnClicked = true;
			this.scrollDirection = "right";
			this.scrollPosition = this.scrollPosition + SCROLL_AMOUNT;
			this.scroll("right", true);
		} else {
			this.scroll("right", false);
		}
	}

	private disableArrows() {
		const scrollLeftTmpIndex = this.currentImageIndex - 1;
		const scrollRightTmpIndex = this.currentImageIndex + 1;

		this.rightDisabled = this.images[scrollRightTmpIndex] === undefined;
		this.leftDisabled = this.images[scrollLeftTmpIndex] === undefined;
	}

	private scroll(direction: string, scrollRequired: boolean) {
		console.log(this.scrollPosition);

		if (scrollRequired) {
			this.scrollRef.scrollXTo(this.scrollPosition, 80).subscribe((complete) => {
				if (!this.scrolled) {
					/* Revert position back */
					if (this.scrollDirection === "left") {
						/* On scrolling left, positively adjust position */
						this.scrollPosition = this.scrollPosition + SCROLL_AMOUNT;
					} else if (this.scrollDirection === "right") {
						/* On scrolling right, negatively adjust position */
						this.scrollPosition = this.scrollPosition - SCROLL_AMOUNT;
					}
				}

				if (this.scrollPosition >= this.scrollMax) {
					/* Scroll has overshot max, reset to max, and disable right arrow */
					this.scrollPosition = this.scrollMax;
				} else if (this.scrollPosition <= 0) {
					/* Scroll has exceeded minimum, reset position to 0, and disable left arrow */
					this.scrollPosition = 0;
				}

				/* Indicate scrolling is finished */
				this.scrolled = false;
				/* Reset scroll from arrow click to false */
				this.scrollOnClicked = false;
				/* No longer scrolling, reset direction to none */
				this.scrollDirection = "none";

				this.scrollToImage(direction);
			});
		} else {
			this.scrollToImage(direction);
		}
	}

	scrollToImage(direction: string) {
		const tmpIndex = direction === "right" ? this.currentImageIndex + 1 : this.currentImageIndex - 1;
		if (this.images[tmpIndex] !== undefined) {
			this.selectImage(this.images[tmpIndex], tmpIndex);
		}
	}

	viewOriginal() {
		console.log("viewing original image in modal");

		const modalRef = this.modalService.open(OriginalImageDialogComponent, {
			windowClass: "modal-original-image",
			size: "lg",
		});
		modalRef.componentInstance.viewingImage = this.displayImage;
		modalRef.componentInstance.customer = this.customer;

		return modalRef;
	}

	moveMultiscanLeft() {
		const increment = -1;
		console.log("moving multiscan left. increment " + increment);
		this.multiscan.moveRight();
		this.moveMultiscanTo(this.currentMultiscanIndex + increment);
	}

	moveMultiscanRight() {
		const increment = 1;
		console.log("moving multiscan right. increment " + increment);
		this.multiscan.moveRight();
		this.moveMultiscanTo(this.currentMultiscanIndex + increment);
	}

	moveMultiscanTo(index) {
		console.log("moving multiscan to index [" + index + "]");
		this.multiscan.moveTo(index);

		if (this.multiscanImages[index] !== undefined) {
			this.currentMultiscanIndex = index;

			console.log("currentMultiscanIndex = " + this.currentMultiscanIndex);
			// this.currentImage = this.multiscanImages[this.currentMultiscanIndex];
			this.multiscanImages[this.currentMultiscanIndex].multiscan = true;
			this.multiscanImages[this.currentMultiscanIndex].multiscanImages = this.multiscanImages;
			this.displayImage = this.multiscanImages[this.currentMultiscanIndex];
		}
	}

	ngOnDestroy() {
		if (this.scrollSubscription) {
			this.scrollSubscription.unsubscribe();
		}
	}

	getThumbnailUrl(image: Image): string | SafeUrl {
		if (image.isPlaceholder) {
			return image.thumbSafeImageUrl;
		}
		const url = image.thumbImageUrl ? image.thumbImageUrl : image.imageUrl;
		return url + (url.indexOf("?") === -1 ? "?" : "&") + "width=250&height=250";
	}

	uploadSuccess(event) {
		console.log("Event Upload Success", event);
		this.displayImage.newImage = event.result;
		this.displayImage.newImageUrl = this.sanitise(event.result);
		this.selectImage(this.displayImage, this.currentImageIndex);
		// this.closeModal();
	}

	uploadError(event) {
		console.log("Upload error event", event);
	}

	saveImage() {
		if (!this.displayImage.newImage) {
			this.notifier.error("Please upload a new image");
			return;
		}

		var imageData = this.displayImage.newImage;
		if (imageData.indexOf("base64,") > 0) {
			imageData = imageData.substring(imageData.indexOf("base64,") + 7, imageData.length);
		}
		var image: Image = {
			id: this.displayImage.id,
			type: this.displayImage.type,
			image: "",
			base64: imageData,
			size: 0,
			ext: "",
		};

		const spinnerModal = this.showSpinner("Saving...");
		this.imageService
			.editImage(this.customer.axonId, image)
			.pipe(
				catchError((error) => {
					spinnerModal.close();
					throw error;
				})
			)
			.subscribe((data) => {
				spinnerModal.close();
				if (!data.success) {
					ServerError.printError(data);
					this.notifier.error(ServerError.formatError(data));
				} else {
					this.notifier.success("Successfully uploaded image");
					this.customerService.getCustomer(this.customer.axonId).subscribe((customerResponse) => {
						this.customer = customerResponse.data;
						this.onRefreshCustomer.emit(this.customer);
					});
				}
			});
	}

	showSpinner(message: string): NgbModalRef {
		const options: NgbModalOptions = {
			backdrop: "static",
			centered: true,
			keyboard: false,
			windowClass: "spinner-modal",
		};

		const spinnerModal = this.modalService.open(AxonSpinnerComponent, options);
		spinnerModal.componentInstance.message = message;
		return spinnerModal;
	}
}
