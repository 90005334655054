import { Component, Input, OnInit, Inject } from "@angular/core";
import { DynamicLink, Agent, DynamicField, RegInfoApprovalData, AgentBlacklistDto } from "../../../../../dto/dtos";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalComponent, CONFIRM } from "../../../../../component/form/confirm-modal/confirm-modal.component";
import { AxonUtils } from "../../../../../utils/axon-utils";
import { DynamicFieldManager } from "../../../../../settings/dynamic-fields/dynamic-field-manager";
import { Section } from "../../../../../utils/constants";
import { environment, SectionUtils } from '../../../../../../environments/environment';

@Component({
	selector: 'app-customer-details-reginfo-approvalinfo',
	templateUrl: './reg-info-approval-info.component.html',
	styleUrls: ['./reg-info-approval-info.component.scss']
})
export class RegInfoApprovalInfoComponent implements OnInit {

	@Input() regInfoApprovalData: RegInfoApprovalData;
	@Input() dynamicLink: DynamicLink;
	@Input() showViewInApproval: boolean;
	@Input() showRegInfo: boolean;
	@Input() showApprovalInfo: boolean;
	@Input() oneColumn: false;

	org: string;
	env = environment;

	constructor(
		private router: Router,
		private dynamicFieldMgr: DynamicFieldManager,
		private dialog: MatDialog,
		private modalService: NgbModal) {
	}

	ngOnInit() {

		if (this.env.show_orgs) {
			this.populateOrg();
		}
	}

	private viewInApproval() {
		this.dialog.closeAll();
		this.router.navigate([this.regInfoApprovalData.viewInApprovalPath]);
		// this.router.navigate(['/approvals/view/' + this.regInfoApprovalData.axonId]);
	}

    /**
     * Displays confirm modal asking user to confirm navigation to the agent page
     * @param agent
     */
	private goToAgent(agent: Agent) {
		const modal = this.modalService.open(ConfirmModalComponent,
			{
				windowClass: "modal-addagent-confirmclose"
			}
		);
		modal.componentInstance.title = 'View agent';
		modal.componentInstance.message =
			'Would you like to navigate away from this page and view the agent: ' + agent.name + ' ' + agent.surname + '?';
		modal.componentInstance.additionalMessage = "If you click 'Yes' any unsaved changes will be lost, "
			+ "and you will be taken away from this " + this.regInfoApprovalData.title + ", to the agent";
		modal.componentInstance.confirmButton = 'Yes';
		modal.componentInstance.cancelButton = 'No';
		modal.componentInstance.isHtml = true;

		modal.result.then((result) => {
			this.handleGoToAgent(agent, result);
		}, (result) => {
			this.handleGoToAgent(agent, result);
		});
	}

    /**
     * If user has confirmed navigation, they will be taken to the agents GUI with the particular
     * agent they clicked on open in the agent modal.
     * @param agent
     * @param result
     */
	private handleGoToAgent(agent: Agent, result) {
		if (result === CONFIRM) {
			this.dialog.closeAll();
			this.router.navigate(['agents', 'view', agent.agentId]);
		}
	}

	private populateOrg() {
		if ( this.regInfoApprovalData.originalAgent.orgId ) {
			const orgDynamicLink: DynamicLink = this.dynamicFieldMgr.getFieldsBySectionId(SectionUtils.getSectionId(Section.AGENT_ADDRESS_DETAILS));

			let orgField: DynamicField = undefined;
			orgDynamicLink.cards.forEach(card => {
				card.fields.forEach(field => {
					console.log(field);
					orgField = field;
				});
			});

			orgField.value = this.regInfoApprovalData.originalAgent.orgId;
			this.org = AxonUtils.getChooserValue(orgField, this.dynamicFieldMgr);
		}
	}
	buildAgentFullName(agent: Agent): string {
		if(!agent) {
			return '';
		}		
		let out = '';
		let seperator = '';
		if (agent && agent.name) {
			out += seperator + agent.name;
			seperator = ' ';
		}
		if (agent && agent.othernames) {
			out += seperator + agent.othernames;
			seperator = ' ';
		}
		if (agent && agent.surname) {
			out += seperator + agent.surname;
			seperator = ' ';
		}
		return out;
	}

	getBlacklistReason(blacklist: AgentBlacklistDto): string {
		if (!blacklist) {
			return '';
		}
		if (blacklist.reasonDesc) {
			return blacklist.reasonDesc
		}
		return blacklist.reason;
	}
}
