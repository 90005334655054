// import { HttpErrorBadRequest, HttpErrorInternalServerError, HttpErrorIllegibleFile } from './http-errors';
// import { IFileUpload } from './../../components/general/file-upload/file-upload.component';
// import { Auth3Service } from './../auth/auth.service';
import { HttpClient, HttpResponse, HttpRequest, HttpErrorResponse, HttpInterceptor, HttpHandler, HttpEvent, HttpHeaders } from "@angular/common/http";
import { AuthService } from '../auth/auth.service';
import { Observable, TimeoutError } from 'rxjs';
import { catchError, map, timeout } from "rxjs/operators";
// tslint:disable-next-line: max-line-length
import { HttpErrorBadRequest, HttpErrorIllegibleFile, HttpErrorInternalServerError, HttpError, HttpErrorUnathorized, HttpErrorTimeout } from '../../utils/http-errors';
import { ServerResponse, Token } from "../../dto/dtos";
import { Injector, Injectable, Inject, InjectionToken } from '@angular/core';
import { NoCryptoKeyFoundError } from "../../error/errors";
import { Notifier } from "../../utils/notifier";
import { environment } from "../../../environments/environment";
import { Storage, KEY_SYSTEM_INFO_VERSION } from "../../utils/storage";
import { version } from '../../../../package.json';

export interface INamedFile {
	name: string;
	file: File;
}

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

export abstract class AbstractHttpService {

		public static BASE_URL = environment.url;
		private static REQUEST_PREFIX = new Date().getTime();
		private static REQUEST_COUNT = 0;

	constructor(private httpClient: HttpClient, private authService: AuthService, private context: string, private notifier: Notifier) { }

		/**
		 * Constructs a URL using the base URL and the context provided in the constructor
		 */
		private buildUrl(path: string): string {
			const requestId = `${AbstractHttpService.REQUEST_PREFIX}_${AbstractHttpService.REQUEST_COUNT}`;
			AbstractHttpService.REQUEST_COUNT = AbstractHttpService.REQUEST_COUNT + 1;

			let out = AbstractHttpService.BASE_URL + this.context + (path === null ? '' : path);
			return out + `${out.indexOf("?") === -1 ? '?' : '&'}request-id=${requestId}&gui_version=${version}`;
		}

		/**
		 * Creates an Observable for get requests
		 */
		protected createGet<T>(path?: string, additionalHeaders?: HttpHeaders): Observable<T> {

			/* Likewise, need to add agentId to the HTTP headers for audit trail stuff */
			const agentId: string = this.getAgentId().toString();
			if (!path.includes("actuator")) {
				console.log("Additional headers: axon-agent-id [" + agentId + "]");
				console.log("Creating get request ", this.buildUrl(path));
			}
			let headers = new HttpHeaders();
			headers = headers.append('axon-token', this.getToken());
			headers = headers.append('axon-agent-id', agentId);
			headers = headers.append('Content-Type', 'application/json');
			headers = headers.append('Cache-control', 'no-cache no-store');
			headers = headers.append('Expires', '0');
			headers = headers.append('Pragma', 'no-cache');
			
			if (additionalHeaders && additionalHeaders.keys().length > 0) {
				additionalHeaders.keys().forEach(key => {
					headers = headers.append(key, additionalHeaders.get(key));
				});
			}
			return this.httpClient.get<T>(this.buildUrl(path), {
				headers: headers
			}).pipe(catchError(error => { throw this.handleError(error); }));
		}

		protected createDelete<T>(data: any, path?: string): Observable<T> {

			/* Likewise, need to add agentId to the HTTP headers for audit trail stuff */
			const agentId: string = this.getAgentId().toString();
			if (!path.includes("actuator")) {
				console.log("Additional headers: axon-agent-id [" + agentId + "]");
				console.log("Creating get request ", this.buildUrl(path));
			}
			let headers = new HttpHeaders();
			headers = headers.append('axon-token', this.getToken());
			headers = headers.append('axon-agent-id', agentId);
			headers = headers.append('Content-Type', 'application/json');
			headers = headers.append('Cache-control', 'no-cache no-store');
			headers = headers.append('Expires', '0');
			headers = headers.append('Pragma', 'no-cache');
			
			return this.httpClient.request<T>("DELETE", this.buildUrl(path), {
				body: data,
				headers: headers
			}).pipe(catchError(error => { throw this.handleError(error); }));
		}		

		protected createFilePost<T>(data: FormData, path?: string, contentType?: string): Observable<T> {

			return this.httpClient.post<T>(this.buildUrl(path), data, {
				headers: {
					"axon-token": this.getToken(),
					'Cache-control': 'no-cache no-store',
					// 'Content-Type': (contentType !== undefined) ? contentType : 'application/json',
					'Expires': '0',
					'Pragma': 'no-cache'
				}
			}).pipe(catchError(error => { throw this.handleError(error); }));
		}

		/**
		 * Creates an Observable for post requests
		 * @param data
		 * @param path Optional path
		 */
		 protected createPostNoAgentId<T>(data: any, path?: string, contentType?: string): Observable<T> {

			/* For all requests, set the agent ID from storage */
			data.agentId = null

			/* Need to add identifierNum to the HTTP headers for audit trail stuff */
			let identifierNum = '';
			if ('identifierNum' in data) {
				identifierNum = data.identifierNum.toString();

			}

			/* Need to add identifierlist to the HTTP headers for audit trail stuff */
			let identifierList = '';
			if ('identifierStringsList' in data) {
				identifierList = data.identifierStringsList;

			}

			/* Need to add the bulk action to the HTTP headers for audit trail stuff */
			let bulkAction = '';
			if ('bulkAction' in data) {
				bulkAction = data.bulkAction.toString();
			}

			/* Likewise, need to add agentId to the HTTP headers for audit trail stuff */
			const agentId: string = this.getAgentId().toString();

			console.log("Creating post request ", this.buildUrl(path), data);
			console.log("Additional headers: axon-agent-id [" + agentId + "] - " +
				"axon-identifier-num [" + identifierNum + "] - identifierStringsList [" + identifierList + "] - " +
				"bulkAction [" + bulkAction + "]");

			console.log("Request data", data);

			return this.httpClient.post<T>(this.buildUrl(path), data, {
				headers: {
					"axon-token": this.getToken(),
					"axon-identifier-num": identifierNum,
					"axon-identifier-list": identifierList,
					"axon-agent-id": agentId,
					"axon-bulk-action": bulkAction,
					'Content-Type': 'application/json',
					'Cache-control': 'no-cache no-store',
					'Expires': '0',
					'Pragma': 'no-cache'
				}
			}).pipe(catchError(error => { throw this.handleError(error); }));
		}

		/**
		 * Creates an Observable for post requests
		 * @param data
		 * @param path Optional path
		 */
		protected createPost<T>(data: any, path?: string, contentType?: string): Observable<T> {

			/* For all requests, set the agent ID from storage */
			data.agentId = this.getAgentId();

			/* Need to add identifierNum to the HTTP headers for audit trail stuff */
			let identifierNum = '';
			if ('identifierNum' in data) {
				identifierNum = data.identifierNum.toString();

			}

			/* Need to add identifierlist to the HTTP headers for audit trail stuff */
			let identifierList = '';
			if ('identifierStringsList' in data) {
				identifierList = data.identifierStringsList;

			}

			/* Need to add the bulk action to the HTTP headers for audit trail stuff */
			let bulkAction = '';
			if ('bulkAction' in data) {
				bulkAction = data.bulkAction.toString();
			}

			/* Likewise, need to add agentId to the HTTP headers for audit trail stuff */
			const agentId: string = this.getAgentId().toString();

			console.log("Creating post request ", this.buildUrl(path), data);
			console.log("Additional headers: axon-agent-id [" + agentId + "] - " +
				"axon-identifier-num [" + identifierNum + "] - identifierStringsList [" + identifierList + "] - " +
				"bulkAction [" + bulkAction + "]");

			console.log("Request data", data);

			return this.httpClient.post<T>(this.buildUrl(path), data, {
				headers: {
					"axon-token": this.getToken(),
					"axon-identifier-num": identifierNum,
					"axon-identifier-list": identifierList,
					"axon-agent-id": agentId,
					"axon-timezone-offset": ""+new Date().getTimezoneOffset(),
					"axon-bulk-action": bulkAction,
					'Content-Type': 'application/json',
					'Cache-control': 'no-cache no-store',
					'Expires': '0',
					'Pragma': 'no-cache'
				}
			}).pipe(catchError(error => { throw this.handleError(error); }));
		}

		protected createBlobPost(data: any, path?: string): Observable<Blob> {

			/* For all requests, set the agent ID from storage */
			data.agentId = this.getAgentId();

			/* Need to add identifierNum to the HTTP headers for audit trail stuff */
			let identifierNum = '';
			if ('identifierNum' in data) {
				identifierNum = data.identifierNum.toString();
			}

			/* Need to add identifierlist to the HTTP headers for audit trail stuff */
			let identifierList = '';
			if ('identifierStringsList' in data) {
				identifierList = data.identifierStringsList;
			}

			/* Need to add the bulk action to the HTTP headers for audit trail stuff */
			let bulkAction = '';
			if ('bulkAction' in data) {
				bulkAction = data.bulkAction.toString();
			}

			/* Likewise, need to add agentId to the HTTP headers for audit trail stuff */
			const agentId: string = this.getAgentId().toString();

			console.log("Creating post request ", this.buildUrl(path), data);
			console.log("Additional headers: axon-agent-id [" + agentId + "] - " +
				"axon-identifier-num [" + identifierNum + "] - identifierStringsList [" + identifierList + "] - " +
				"bulkAction [" + bulkAction + "]");
			console.log("Request data", data);

			return this.httpClient.post<Blob>(this.buildUrl(path), data, {
				headers: {
					"axon-token": this.getToken(),
					"axon-identifier-num": identifierNum,
					"axon-identifier-list": identifierList,
					"axon-agent-id": agentId,
					"axon-bulk-action": bulkAction,
					'Content-Type': 'application/json',
					'Cache-control': 'no-cache no-store',
					'Expires': '0',
					'Pragma': 'no-cache'
				},
				responseType: 'blob' as 'json'
			}).pipe(catchError(error => { throw this.handleError(error); }));
		}

		/**
		 * Helper to return the auth token from local storage.
		 * NOTE: as this is used in the HTTP header, if the header
		 * is null, it will cause errors that don't properly explain the
		 * cause. Therefore, this method returns empty string, to avoid
		 * nulls.
		 */
		private getToken(): string {
			// try {
			const authToken: Token = this.authService.getAuthToken();
			const token: string = (authToken === null) ? null : authToken.token;

			if (token) {
				return token;
			} else {
				return '';
			}
			// } catch ( e ) {
			//     if ( e instanceof NoCryptoKeyFoundError ) {
			//         return '';
			//     }
			// }
		}

	    /**
	     * Helper to return the agent ID from the local storage.
	     * If no agent ID exists in storage, -1 is returned.
	     */
		private getAgentId(): number {
			const agentId: number = this.authService.getAuthAgent().agentId;

			if (agentId) {
				return agentId;
			} else {
				return -1;
			}
		}

		protected handleError(input: HttpErrorResponse): HttpError {
			console.error(`Handling HttpError.  StatusCode:${input.status}`, input);
			if (input instanceof TimeoutError) {
				console.error(`HttpError is a timeout error.`);
				this.notifier.error("Timeout occurred. Please check network and try again");
				return new HttpErrorTimeout(input);
			}
			// not authed
			if (input.status === 401) {
				console.error(`HttpError is an access denied error.  Going to logout and redirect`);
				this.authService.doLogoutAndRedirect();
				return new HttpErrorUnathorized(input);
			}

			if ( input.error ) {
				switch (input.error.code) {
					case 1: {
						console.error(`HttpError is an illegible file???`);
						return new HttpErrorIllegibleFile(input);
					}
				}
			}

			if (input.status === 400) {
				console.error(`HttpError is a bad request`);
				return new HttpErrorBadRequest(input);
			}

			if (input.status === 405) {
				console.error(`HttpError is method not allowed`);
				return new HttpErrorBadRequest(input);
			}

			console.error(`HttpError is an internal server error`);
			return new HttpErrorInternalServerError(input);
		}
}

@Injectable({ providedIn: 'root' })
export class TokenAndVersionInterceptor implements HttpInterceptor {
	constructor(private inj: Injector) { }
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		const authService = this.inj.get(AuthService);
		const storage = this.inj.get(Storage);

		return next.handle(request).pipe(map((event: HttpEvent<any>) => {
			console.log("Intercepted request.  ", event);
			if (event instanceof HttpResponse) {
				const version = this.getVersion(event);
				const token = this.getToken(event);
				

				if (version) {
					console.log(`Intercepted request.  Replacing ${KEY_SYSTEM_INFO_VERSION}`,  version);					
					storage.saveItem(KEY_SYSTEM_INFO_VERSION, version);
				}

				const authedAgent = authService.getAuthAgent();
				if (authedAgent.agentId > 0 && token) {
					console.log(`Intercepted request.  Auth token`,  token);					
					authService.saveAuth(token, authService.getAuthAgent());
				}
			}
			return event;
		}));
	}  
	private getVersion(event: HttpResponse<any>) {
		if (event.headers.has('api_version')) {
			return event.headers.get('api_version');
		}
		return (event.body).version;
	}
	private getToken(event: HttpResponse<any>) {
		if (event.headers.has('auth_token') && event.headers.has('auth_expiry')) {
			return {
				token: event.headers.get('auth_token'),
				status: 'VALID',
				expiry: new Date(+event.headers.get('auth_expiry')),
				expiryInSeconds: 0
			};
		}
		return (event.body).token;
	}
}

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
	constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const timeoutValue = Number(req.headers.get('timeout')) || this.defaultTimeout;

		return next.handle(req).pipe(timeout(timeoutValue));
	}
}
