// import { AbstractDto } from './dtos';
import { expand } from "rxjs/operators";
import { TableData } from "../component/table/table.component";
import { FilterOption } from "../component/material-table/material-table.component";
import { FormControl } from "@angular/forms";
import { AxonComponent } from "../axon.component";
import { AdvancedSearchCriteria } from "../approvals/advanced-search/advanced-search.component";
import { Section } from "../utils/constants";
import { SafeUrl } from "@angular/platform-browser";
import { Observable } from "rxjs";

export interface PinProtection {
	id?: number;
	agentId?: number;
	pin?: number;
	dateIssued?: number;
	expiry?: number;
}

export interface Token {
	token: string;
	status: string;
	expiry: Date;
	expiryInSeconds?: number;
}

export interface AuthRequest {
	token: Token;
}

export interface ServerResponse {
	success?: boolean;
	error?: string;
	errorCode?: number;
	errorCategory?: number;
	token?: Token;
	version?: string;
}

export interface AxonModel {
	elementId?: number;
	selected?: boolean;
}

export interface Agent extends AxonModel {
	agentId?: number;
	name?: string;
	surname?: string;
	msisdn?: string;
	username?: string;
	id?: string;
	birthdate?: any;
	imageUrl?: string;
	permissions?: any[];
	roles?: any[];
	sims?: any[];
	selected?: boolean;
	status?: string;
	accountStatus?: string;
	addressLatitude?: number;
	addressLongitude?: number;
	nationality?: string;
	role?: string;
	othernames?: string;
	dealerCode?: string;
	lastAccessed?: Date;
	devices?: any;
	fraudFlagged?: boolean;
	lastLatitude?: string;
	lastLongitude?: string;
	isPosCoordinator?: boolean;
	orgId?: number;
	shopName?: string;
	region?: string;
	approvalRating?: string;
	approvalRatingCount?: string;
	dmsNodeId?: number;
	blacklist?: AgentBlacklistDto;
}

export interface AuditTrail {
	datetime: Date;
	device: any[];
	webGuiPage: string;
	action: string;
	details: string;
}

export interface AvailablePermissions {
	permission: string;
	description: string;
}

export interface Msisdn {
	axonId?: number;
	msisdn?: string;
	imsi?: string;
	iccid?: string;
	serial?: string;
	puk2?: string;
	status?: string;
	contractType: string;
	serviceType: string;
	activation?: string;
	activationDatetime?: Date;
	activationError?: string;
	deactivation?: string;
	deactivationDatetime?: Date;
	deactivationError?: string;
	originalAgent?: Agent;
	captureAgent?: Agent;
	approvalStatus?: string;
	recipientPendingTransferAxonId?: number;
	selected?: boolean;
}

export interface AxonIdMapMsisdn {
	pinref?: number;
	axonId?: number;
	msisdn?: string;
	iccid?: string;
	serviceType?: string;
	reserverAgentId?: number;
	status?: string;
	reserverIMEI?: string;
	dateLoaded?: Date;
	dateRegistered?: Date;
	dateReserved?: Date;
	dateCaptured?: Date;
	dateSmsed?: Date;
	dateSynced?: Date;
	reservationExpiryServer?: Date;
	reservationExpiryClient?: Date;
	reserverApp?: string;
	reservedId?: number;
	offlineExtended?: boolean;
}

export interface Card {
	axonId?: number;
	uuid?: string;
	cuid?: string;
	id?: string;
	idType?: string;
	cardPrintDatetime: Date;
	cardReprintDatetime: Date;
	cardStatus: String;
	cardStatusDatetime: Date;
	originalAgent?: Agent;
	captureAgent?: Agent;
	approvalStatus?: string;
}

export interface Image {
	id: number;
	type: string;
	image: any;
	size: number;
	ext: string;
	pagesCount?: number;
	datetime?: Date;
	thumbImageUrl?: string;
	thumbSafeImageUrl?: SafeUrl;
	imageUrl?: string;
	safeImageUrl?: SafeUrl;
	fieldId?: number;
	rejectionCheckControl?: FormControl;
	rejectionReasonControl?: FormControl;
	showRejectionReason?: boolean;
	rejectionReason?: string;
	multiscan?: boolean;
	pageNumber?: number;
	multiscanId?: number;
	multiscanImages?: Array<Image>;
	rotation?: number;
	hashCode?: number;
	isPlaceholder?: boolean;
	isMandatory?: number;
	hidden?: number;
	field?: DynamicField;
	newThumbImageUrl?: SafeUrl;
	newImageUrl?: SafeUrl;
	newImage?: any;
	base64?: any;
}

export interface FicaReject {
	axonId: number;
	pinref: number;
	agentId: number;
	agentDetails: string;
	fieldId: number;
	field?: string;
	fieldReason: string;
	recordReason: string;
	datetime: Date;
}

export interface Customer {
	selected: boolean;
	axonId: number;
	pinref: number;
	profileType: string;
	type: string;
	name: string;
	surname: string;
	otherName: string;
	id: string;
	idType: string;
	idExpiry: Date;
	birthDate: Date;
	birthPlace: string;
	title: string;
	gender: string;
	address1: string;
	address2: string;
	address3: string;
	addressTown: string;
	addressCity: string;
	addressProvince: string;
	addressFull: string;
	addressFound: boolean;
	approvalStatus: string;
	approvedNote: string;
	approvedDatetime: Date;
	originalDatetime: Date;
	originalAgent: Agent;
	originalLongitude: string;
	originalLatitude: string;
	originalLatLngAddress: string;
	originalTime?: number;
	captureAgent: Agent;
	captureDatetime?: Date;
	captureVersion?: string;
	captureIp?: string;
	captureDeviceId?: string;
	captureDeviceBlacklist?: AgentBlacklistDto;
	approvalAgent: Agent;
	submissionTime?: number;
	submissionDatetime?: Date;
	recordAction?: string;
	recordType?: string;
	channel?: string;
	uploadStatus?: string;
	comment?: string;
	/* Comma separated list of MSISDNs */
	msisdn: string;
	msisdnActivation: string;
	/* List of MSISDNs */
	msisdns?: Msisdn[];
	/* List of Images */
	images: Image[];
	imageUrl?: string;
	importedPdfUrl: string;
	/* List of Cards */
	cards?: Card[];
	timeToApproveMillis?: number;
	rejectedFields?: FicaReject[];
	expirySeconds?: number;
	/* Approval queue columns */
	dateAdded: Date;
	linkExpiry: Date;
	approvalExpired: boolean;
	captureApp?: string;
	submissionApp?: string;
	availableImageTypes?: string[];
	dmsNodeId?: number;
}

export interface Device extends AxonModel {
	deviceId: string;
	deviceType?: string;
	make?: string;
	model?: string;
	firmware?: string;
	version?: string;
	di?: string;
	status?: string;
	activeDevice?: string;
	dateProvisioned?: Date;
	provisioningAgentId?: number;
	usedFirst?: Date;
	usedLast?: Date;
	lastAgentId?: number;
	lastAgentMsisdn?: string;
	lastLatitude?: string;
	lastLongitude?: string;
	lastCellId?: string;
	logincount?: number;
	provisioningAgent?: Agent;
	users?: DeviceUser[];
	recentCustomers?: Customer[];
}

export interface DeviceUser {
	username: string;
	lastSeen: string;
	provisioned: string;
	imageUrl?: string;
}

export interface Submission {
	id: number;
	axonId: number;
	pinref: number;
	msisdn: string;
	iccid: string;
	type: string;
	queueDate: Date;
	startDate: Date;
	endDate: Date;
	timing: number;
	attempt: number;
	status: string;
	tpRef: string;
	tpCode: string;
	tpMsg: string;
	tpRequest: any;
	tpResponse: any;
	error: string;
}

export interface Category {
	category: string;
	subCategory: string;
	level: string;
}

export interface Node {
	id: number;
	value: string;
	children: Array<Node>;
	axonInternalId: number;
}

export interface LinkedLists {
	categoryIndividual: Array<Node>;
	categoryCorporate: Array<Node>;
	orgs: Array<Node>;
}

export interface Permission {
	role: string;
	permission: string;
	agentId: number;
	system: string;
	category: string;
	description: string;
	expiry: Date;
}

export interface AuthResponse extends ServerResponse {
	agent?: Agent;
	dynamicFieldVersion?: number;
	dynamicLinks?: Array<DynamicLink>;
	dynamicActions?: Array<DynamicFieldAction>;
	dynamicValidations?: Array<DynamicValidationDto>;
	linkedLists?: LinkedLists;
	roleRules?: Array<RoleRule>;
	loginAttempts?: any;
	killedOtherSession?: boolean;
	groupListsVersion?: number;
	groupLists?: Map<number, Array<GroupLists>>;
	permissions?: Array<Permission>;
	browserToken?: string;
}

export interface DataRequest {
	agentId?: number;
	pageNum?: number;
	pageMax?: number;
	sort?: string;
	order?: string;
	identifierNum?: number;
	identifierStr?: string;
	sectionId?: number;
	cardId?: number;
	filterOptions?: FilterOption[];
	dynamicLink?: DynamicLink;
	dynamicLinks?: DynamicLink[];
	dynamicField?: DynamicField;
	username?: string;
	password?: string;
	currentPassword?: string;
	newPassword?: string;
	confirmPassword?: string;
	msisdn?: string;
	msisdns?: Array<Msisdn>;
	answers?: any;
	bulkAction?: string;
	agentList?: Agent[];
	agentIdList?: number[];
	agentUsernamesList?: string[];
	nameValuesList?: any;
	identifierStringsList?: string[];
	reason?: string;
	value?: string;
	type?: string;
	description?: string;
	userAction?: boolean;
	pinref?: number;
	types?: string[];
	ficaOrganization?: FicaOrganization;
	pin?: PinProtection;
	dmsNodeId?: number;
	tpClose?: boolean;
	churnList?: string[];
	image?: Image;
}

export interface ImageRequest extends DataRequest {
	imageId?: number;
	multiscanId?: number;
	pageNumber?: number;
	includeMultiscanThumbs?: boolean;
	width?: number;
	height?: number;
	maxWidth?: number;
	maxHeight?: number;
}

export interface FileRequest extends DataRequest {
	file?: FormData;
}

export interface RolesConfigRequest extends DataRequest {
	bulkAction?: string;
	role?: Role;
	roles?: Role[];
	reason?: string;
}

export interface AgentBlacklistDto {
	id?: number;
	blacklistedAgentId?: number;
	blacklistedUsername?: number;
	deviceId?: string;
	createdAt?: Date;
	flaggedByAgentId?: number;
	flaggedByAgentUsername?: string;
	flaggedBySystem?: number;
	axonId?: number;
	reason?: string;
	reasonDesc?: string;
}

export interface AgentBlacklistHistoryDto {
	id: number;
	agentId: number;
	blacklistedAgentUsername: string;
	deviceMake: string;
	deviceModel: string;
	action: string;
	createdAt: Date;
	flaggedByAgentId: number;
	isFlaggedBySystem: string;
	isFlaggedByAgent: string;
	flaggedByAgentUsername: string;
	axonId: number;
	reason: string;
	reasonDesc: string;
}

export interface DataResponse extends ServerResponse {
	tableData?: TableData;
	totalCount?: number;
	dataList?: any[];
	data?: any;
}

export interface Role {
	role: string;
	description?: string;
	roleType?: any;
	deletable?: boolean;
	permissions?: AgentRolePermission[];
	availablePermissions?: AgentRolePermission[];
	elementId?: string;
	selected?: boolean;
}

export interface AgentRolePermission {
	permission: string;
	description: string;
	expiry: Date;
	selected: boolean;
	updated: boolean;
}

export interface Organization {
	id: number;
	name: string;
	parentId: number;
	orgType: string;
	areaId: number;
	active: boolean;
	activeDate: Date;
	orgLevel: any;
	orgClass: any;
	region: string;
}

export interface DynamicLinkRequest {
	dynamicLinkForServer: DynamicLink;
	pin: PinProtection;
}

/***************************************************************** */
// Dynamic Fields / Links / Card
/***************************************************************** */

// Dynamic Link - acts as both the link, and the section details
export class DynamicLink {
	sectionId: number;
	section: string;
	sectionEnum?: Section;
	mandatory?: number;
	cards: Array<DynamicCard>;

	/**
	 * Copy constructor - if a DynamicLink object is given, it will copy
	 * the properties and construct a completely new reference.
	 * @param dynamicLink
	 */
	constructor(dynamicLink?: DynamicLink) {
		if (dynamicLink) {
			this.sectionId = dynamicLink.sectionId;
			this.section = dynamicLink.section;

			const cards: Array<DynamicCard> = new Array();
			for (const origCard of dynamicLink.cards) {
				const fields: Array<DynamicField> = new Array();

				for (const origField of origCard.fields) {
					const dynamicField: DynamicField = {
						id: origField.id,
						field: origField.field,
						charType: origField.charType,
						inputField: origField.inputField,
						min: origField.min,
						max: origField.max,
						allowSpaces: origField.allowSpaces,
						charsAllowed: origField.charsAllowed,
						lov: origField.lov,
						containsVowels: origField.containsVowels,
						sequentialChar: origField.sequentialChar,
						params: origField.params,
						value: origField.value,
						mandatory: origField.mandatory,
						faceDetect: origField.faceDetect,
						faceDetectType: origField.faceDetectType,
						editable: origField.editable,
						defaultValue: origField.defaultValue,
						hidden: origField.hidden,
						lovValues: origField.lovValues,
						formControl: origField.formControl,
						patternError: origField.patternError,
						fieldMapping: origField.fieldMapping,
						formComponent: origField.formComponent,
						rejectionCheckControl: origField.rejectionCheckControl,
						rejectionReasonControl: origField.rejectionReasonControl,
						showRejectionReason: origField.showRejectionReason,
						rejectionReason: origField.rejectionReason,
						pattern: origField.pattern,
						invalidError: origField.invalidError,
						permissions: origField.permissions,
					};

					fields.push(dynamicField);
				}

				const card: DynamicCard = {
					id: origCard.id,
					card: origCard.card,
					type: origCard.type,
					fields: fields,
					elementId: origCard.id,
				};

				cards.push(card);
			}

			this.cards = cards;
		}
	}
}

// Dynamic Card
export interface DynamicCard extends AxonModel {
	id?: number;
	card?: string;
	type?: string;
	fields?: Array<DynamicField>;
}

// Dynamic Field
export class DynamicField {
	id: number;
	field?: string;
	charType?: string;
	inputField?: string;
	min?: number; /*done*/
	max?: number; /*done*/
	allowSpaces?: number;
	charsAllowed?: number;
	lov?: number;
	containsVowels?: number;
	sequentialChar?: number;
	uniqueness?: number;
	params?: string;
	value?: any;
	/* The below are properties from the dynamic_link table */
	mandatory?: number; /*done*/
	faceDetect?: number;
	faceDetectType?: string;
	editable?: number; /*done*/
	defaultValue?: any;
	hidden?: number;
	/* Actual List of Values */
	lovValues?: any[];
	/* Form controls */
	formControl?: FormControl;
	/* The axon component that is used to collect this field's value */
	formComponent?: AxonComponent;
	patternError?: string;
	fieldMapping?: DynamicFieldMapping;
	linkedListValue?: Array<Node>;
	rejectionCheckControl?: FormControl;
	rejectionReasonControl?: FormControl;
	showRejectionReason?: boolean;
	rejectionReason?: string;
	pattern?: string;
	invalidError?: string;
	/* Used in multi tier linked list component. The placeholder is defined in params, and set here */
	placeholder?: string;
	/* Calculated when building the field for validation purposes */
	minDate?: Date;
	maxDate?: Date;
	permissions?: string;

	constructor(origField: DynamicField) {
		this.id = origField.id;
		this.field = origField.field;
		this.charType = origField.charType;
		this.inputField = origField.inputField;
		this.min = origField.min;
		this.max = origField.max;
		this.allowSpaces = origField.allowSpaces;
		this.charsAllowed = origField.charsAllowed;
		this.lov = origField.lov;
		this.containsVowels = origField.containsVowels;
		this.sequentialChar = origField.sequentialChar;
		this.uniqueness = origField.uniqueness;
		this.params = origField.params;
		this.value = origField.value;
		this.mandatory = origField.mandatory;
		this.faceDetect = origField.faceDetect;
		this.faceDetectType = origField.faceDetectType;
		this.editable = origField.editable;
		this.defaultValue = origField.defaultValue;
		this.hidden = origField.hidden;
		this.lovValues = origField.lovValues;
		this.formControl = origField.formControl;
		this.patternError = origField.patternError;
		this.fieldMapping = origField.fieldMapping;
		this.formComponent = origField.formComponent;
		this.rejectionCheckControl = origField.rejectionCheckControl;
		this.rejectionReasonControl = origField.rejectionReasonControl;
		this.showRejectionReason = origField.showRejectionReason;
		this.rejectionReason = origField.rejectionReason;
		this.pattern = origField.pattern;
		this.invalidError = origField.invalidError;
		this.permissions = origField.permissions;
	}
}

export interface DynamicFieldMapping extends AxonModel {
	id: number;
	fieldId: number;
	columnName: string;
	tableName: string;
}

export interface RoleRule {
	role: string;
	rules: Array<string>;
}

export interface LoginRequest extends DataRequest {
	username: string;
	password?: string;
	dynamicFieldVersion?: number;
	otp?: string;
	memorableAnswers?: any;
	companyId?: number;
	groupListsVersion?: number;
	browserToken?: string;
}

export interface GroupListRequest extends DataRequest {
	grouplists: {
		text: string;
		position?: number;
		id?: number;
		language?: Text;
		otherId?: number;
		maxGrouplistPosition?: number;
	};
	pin: {
		id?: number;
		agentId?: number;
		pin?: number;
		dateIssued?: number;
		expiry?: number;
	};
}

export interface AppDataRequest extends DataRequest {
	appdata: {
		name: string;
		value: string;
		description?: string;
	};
	pin: {
		id?: number;
		agentId?: number;
		pin?: number;
		dateIssued?: number;
		expiry?: number;
	};
}

export interface PasswordStrengthRequest extends DataRequest {
	minLength: number;
	maxLength: number;
	maxRepeatChars: number;
	noRepeatChars: boolean;
	forceDigits: boolean;
	forceLowercase: boolean;
	forceUppercase: boolean;
	forceSpecial: boolean;
}

export interface AuditAction extends DataRequest {
	id: number;
	action: string;
}

export interface SystemAuditTrail extends DataRequest {
	id?: number;
	loginId?: number;
	agentId?: number;
	device?: any[];
	agentMsisdn?: string;
	agentFullname?: string;
	deviceId?: string;
	deviceType?: string;
	longitude?: string;
	latitude?: string;
	cellId?: string;
	version?: string;
	dbVersion?: string;
	browser?: string;
	ip?: string;
	toAgentId?: number; // Used if an agent is being acted upon. i,e Agent Smith is editing Agent 007
	toAgentMsisdn?: string; // Used if an agent is being acted upon. i,e Agent Smith is editing Agent 007
	toAgentFullname?: string;
	toDeviceId?: string; // Used if a device is being acted upon. i,e Agent Smith is editing Device ID 13494992
	toPinref?: number;
	toAxonId?: number;
	toMsisdn?: string;
	dbAction?: string; // CRUD (CREATE, READ, UPDATE, DELETE)
	actionId?: number;
	action?: string; // audit action
	webGuiPage?: string;
	item?: string; //AGENT, REGISTRATION
	referenceTable?: string;
	referenceNewId?: number;
	referenceOldId?: number;
	dateFromStr?: string;
	dateEndStr?: string;

	limit?: number;
	first?: number;
	last?: number;

	//Stores details of the audit when calling Justin's function FUNC_DET_AT_DETAIL
	//Explains what happened in this audi i.e. REMOVE SIM 550000190, JC blocked Bob, etc
	details?: string;

	pageNum?: number;
	pageMax?: number;
}

export interface Device extends DataRequest {
	deviceId: string;
}

export interface ApprovalStatistics {
	approved: number;
	rejected: number;
	rejectedFinal: number;
	pending: number;
	total: number;
}

export interface ApprovalAxonCache {
	openRecords: number;
	agentCnt: number;
	timestamp: Date;
}

export interface ApprovalPerformance {
	avgActionTime: number;
	avgActionsPerHour: number;
	avgActionTimeOthers: number;
	avgActionsPerHourOthers: number;

	approvalStatsToday: ApprovalStatistics;
	approvalStatsLastHour: ApprovalStatistics;

	approvalAxonCache: ApprovalAxonCache;
}

export interface ApprovalRequest extends DataRequest {
	axonId?: number;
	pinref?: number;
	rejectionReason?: string;
	reasons?: Array<DynamicField>;
	finalReject?: boolean;
	pendingReason?: string;
	approvalMode?: string;
	approvalStatus?: string;
	searchCriteria?: AdvancedSearchCriteria;
	expirySeconds?: number;
	bulkApprovalAction?: boolean;
	/* Indicates whether or not this is from approval override */
	override?: boolean;
	/* Used only for approval quality review */
	approvalAgentId?: number;
	approvalReviewAcceptable?: string;
	approvalReviewComment?: string;
}

export interface Range extends AxonModel {
	selected?: boolean;
	id?: number;
	agentId?: number;
	rangeStart?: string;
	rangeEnd?: string;
	rangeMin?: number;
	rangeMinOptions?: any;
	rangeMax?: number;
	rangeMaxOptions?: any;
	iccidStart?: any;
	iccidEnd?: any;
	atId?: number;
	action?: string;
	startDate?: Date;
	endDate?: Date;
}

export interface AllocateRangesRequest extends DataRequest {
	addedList?: Array<Range>;
	editedList?: Array<Range>;
	deletedList?: Array<Range>;
}

export interface GroupLists {
	id: number;
	position: number;
	language: string;
	otherId: number;
	text: string;
	otherText: string;
}

/**
 * Entity representation of DYNAMIC_FIELD_ACTION_RULE table
 *
 * The rule is the physical action performed on the target field.
 * This could be making the field mandatory, or applying regex, or
 * changing min / max, etc.
 *
 * The actual field that is affected is defined in the Target.
 */
export class DynamicFieldActionRule {
	id: number;
	type: string;
	value: any;
	error: string;

	constructor(other: DynamicFieldActionRule) {
		this.id = other.id;
		this.type = other.type;
		this.value = other.value;
		this.error = other.error;
	}
}

/**
 * Entity representation of DYNAMIC_FIELD_ACTION_CONDITION table
 *
 * An Action may have one or more Conditions. These conditions control
 * whether or not the action should be fired. All of the conditions need
 * to be true in order to fire the Action
 */
export class DynamicFieldActionCondition {
	id: number;
	conditionId: number;
	operand: string;
	fieldId: number;
	grouplistId: number;
	grouplistPosition: number;
	grouplistLang: string;
	value: string;
	/*
    If this is true, the condition will only fire if it's true.
    If this is false, the condition will only fire if it's false.
    By default a condition will fire if false (condition not satisfied)
    */
	fireBool: boolean;

	constructor(other: DynamicFieldActionCondition) {
		this.id = other.id;
		this.conditionId = other.conditionId;
		this.operand = other.operand;
		this.fieldId = other.fieldId;
		this.grouplistId = other.grouplistId;
		this.grouplistPosition = other.grouplistPosition;
		this.grouplistLang = other.grouplistLang;
		this.value = other.value;
		this.fireBool = other.fireBool;
	}

	isGrouplistValue(): boolean {
		return this.grouplistId !== 0;
	}

	isStandardValue(): boolean {
		return this.value !== null && this.value !== undefined;
	}
}

/**
 * Entity representation of DYNAMIC_FIELD_ACTION_TARGET table.
 *
 * This describes the field that is affected by the rule.
 */
export class DynamicFieldActionTarget {
	id: number;
	targetId: number;
	fieldId: number;
	rule: DynamicFieldActionRule;

	constructor(other: DynamicFieldActionTarget) {
		this.id = other.id;
		this.targetId = other.targetId;
		this.fieldId = other.fieldId;
		this.rule = new DynamicFieldActionRule(other.rule);
	}
}

/**
 * Represents the table DYNAMIC_FIELD_ACTION - this ties in the conditions, targets and rules
 */
export class DynamicFieldAction {
	id: number;
	srcFieldId: number;
	targets: Array<DynamicFieldActionTarget>;
	conditions: Array<DynamicFieldActionCondition>;

	constructor(other: DynamicFieldAction) {
		this.id = other.id;
		this.srcFieldId = other.srcFieldId;

		this.targets = new Array<DynamicFieldActionTarget>();
		for (const target of other.targets) {
			this.targets.push(new DynamicFieldActionTarget(target));
		}

		this.conditions = new Array<DynamicFieldActionCondition>();
		for (const condition of other.conditions) {
			this.conditions.push(new DynamicFieldActionCondition(condition));
		}
	}
}

export interface DynamicValidationDto {
	id: number;
	type: string;
	mappings: Array<DynamicValidationMappingDto>;
}

export interface DynamicValidationMappingDto {
	id: number;
	validationId: number;
	fieldId: number;
	mapping: string;
}

export interface ChartData {
	title: string;
	value: any;
}

export interface Chart {
	title: string;
	type: string;
	data: Array<ChartData>;
	comboData: Array<Array<any>>;
	columnNames: Array<string>;
	params: string;
	row: number;
	options: any;
}

export interface ChartRow {
	row: number;
	charts: Array<Chart>;
}

export interface ChurnFile {
	id: number;
	batchId: number;
	file: any;
	fileName: string;
	fileType: string;
}

export interface ChurnBatch {
	id: number;
	file: ChurnFile;
	service: string;
	status: string;
	agent: Agent;
	dateLoaded: Date;
	dateCreated: Date;
	dateStarted: Date;
	dateCompleted: Date;
	totalMSISDNs: number;
	totalProcessed: number;
	totalPending: number;
	totalFailed: number;
	timeProcessed: string;
}

export interface SystemInfo {
	build: SystemInfoBuild;
}
export interface SystemInfoBuild {
	artifact: string;
	name: string;
	time: string;
	version: string;
	group: string;
}

export interface AbstractDto {
	hashCode?: number;
}
export interface ReportCategoryDto extends AbstractDto {
	cat: string;
	displayEn: string;
	displayEs: string;
}
export interface ReportDto extends AbstractDto {
	id: number;
	name: string;
	displayEn: string;
	displayEs: string;
	description: string;
	type: string;
	displayOrder: number;
	displayDefault: boolean;
	displayCategory: ReportCategoryDto;
	parameters: ReportParameterDto[];
	parameterData: { [id: number]: ReportQueryResultDto }[];
}
export interface ReportParameterDto extends AbstractDto {
	id: number;
	name: string;
	type: "PASTDATE" | "FUTUREDATE" | "COMBO" | "TEXT" | "ORGANIZATION";
	min: number;
	max: number;
	required: boolean;
}
export interface ReportQueryResultDto extends AbstractDto {
	columns: string[];
	types: string[];
	rows: ReportQueryResultRowDto[];
	query: string;
	parameters: string[];
}
export interface ReportQueryResultRowDto extends AbstractDto {
	data: string[];
}
export interface ReportWithDataDto {
	report: ReportDto;
	data: ReportQueryResultDto;
}

export interface AgentTracingCoordinat {
	type: "Login" | "Registration" | "Default";
	latitude: number;
	longitude: number;
	hashCode: number;
}

export interface CustomerTracingCoordinate {
	type: "Registration" | "Default";
	latitude: number;
	longitude: number;
	hashCode: number;
}

export interface FormDataDto {
	/* The identifier i.e agentId, axonId etc */
	id: number;
	/* The dynamic link used to construct the form fields. If new mode, there should be no values. In edit mode, the dynamic link must contain values */
	data: DynamicLink;
}

export interface DynamicValidationDataDto {
	fieldId: number;
	value: string;
}

export interface DynamicValidationResultDto {
	valid: boolean;
	message: string;
	hashCode: number;
}

export interface MomokashData {
	axonId: number;
	msisdn: string;
	username: string;
	type: string;
	approvalStatus: string;
	originalAgent: Agent;
	captureAgent: Agent;
	approvalAgent: Agent;
}

export interface AbstractMomokashUser {
	axonId: number;
	pinref: number;
	msisdn: string;
	username: string;
	comments: string;
	type: string;
	approvalStatus: string;
	approvalNote: string;
	approvalDate: Date;
	recordAction: string;
	recordType: string;
	uploadStatus: string;
	originalDatetime: Date;
	originalTime: number;
	originalLongitude: string;
	originalLatitude: string;
	captureDatetime: Date;
	captureIp: string;
	captureDeviceId: string;
	captureVersion: string;
	captureApp: string;
	submissionTime: number;
	submissionDatetime: string;
	originalAgent: Agent;
	captureAgent: Agent;
	approvalAgent: Agent;
	rejectedFields?: FicaReject[];
	images?: Image[];
	cards?: Card[];
	submittedMm?: string;
	submittedMmDatetime?: Date;
	submittedMmError?: string;
	submittedMmSubQueueId?: number;
	submittedMmTpRequest?: string;
	submittedMmTpResponse?: string;
}

export interface MomokashUserAgent extends AbstractMomokashUser {
	firstname: string;
	lastname: string;
	prospectCommercialName: string;
	prospectRegion: string;
	prospectQuality: string;
	taxRegime: string;
	taxRegimeNumber: string;
	rccmNumber: string;
	level: string;
	parentAxonId: number;
	username: string;
	comments: string;
}

export interface MomokashUserHandlerPos extends AbstractMomokashUser {
	firstname: string;
	lastname: string;
	posNumber: string;
	parentAxonId: number;
}

export interface MomokashUserMerchant extends AbstractMomokashUser {
	companyName: string;
}

export interface RegInfoApprovalData {
	axonId: number;
	approvalStatus: string;
	approvedNote: string;
	approvedDatetime: Date;
	originalDatetime: Date;
	cards: Card[];
	rejectedFields: FicaReject[];
	captureDeviceId: string;
	captureDeviceBlacklist: AgentBlacklistDto;
	captureApp: string;
	captureVersion: string;
	captureIp: string;
	recordAction: string;
	originalAgent: Agent;
	captureAgent: Agent;
	approvalAgent: Agent;
	viewInApprovalPath: string;
	title: string;
	customerType?: string;
}

export interface FicaOrganization {
	pinref: number;
	axonId: number;
	id: string; //this is the msisdn
	name: string;
}

export interface ChangeOwner {
	axonId: number;
	name: string;
	surname: string;
	id: string;
	msisdns: Array<Msisdn>;
}

export interface TpReQueueRequest {
	axonId?: number;
	pinref?: number;
	msisdn?: string;
	queueId?: number;
}
export interface DmsNodeInfoDto {
	axonId: number;
	createdAt: Date;
	parentId?: number;
	name: string;
	properties: { key: string; value: any }[];
	type: DmsNodeTypeDto;
	allowedChildTypes: DmsNodeTypeDto[];
	agentHasAccess: boolean;
}
export interface DmsNodeTypeDto {
	id: number;
	type: string;
	dynamicCreateSectionId?: number;
	dynamicModifySectionId?: number;
}

export interface SelfReg {
	axonId: number;
	createdAt: Date;
	userRef: string;
	pin: string;
	status: string;
	sectionId: number;
	recordAction: string;
	profileType: string;
	contractType: string;
	data: string;
}

export interface SelfRegImage {
	imageId: number;
	axonId: number;
	fieldId: number;
	image: any;
	imageType: string;
	imageExt: string;
}

export interface SelfRegDataDto {
	selfReg: SelfReg;
	sectionId: number;
	fields: DynamicField[];
	images: SelfRegImage[];
}

export interface EFlow {
	id: number;
	createdAt: Date;
	axonId: number;
	imageId: number;
	status: string;
	statusMessage: string;
	statusAt: Date;
	fileName: string;
	imageName: string;
}

export interface ImagesReport {
	axonId: number;
	customerFirstName: string;
	receiptNumber: string;
	imageType: string;
	faceMetadata: string;
	parsedMetadata: string[];
	b64Image: string;
}
