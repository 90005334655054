import { SecuredImgComponent } from "./component/secured-img/secured-img.component";
import { MaterialTableComponent } from "./component/material-table/material-table.component";
import { InjectionToken, NgModule } from "@angular/core";
import { AxonMaterialModule } from "./material-module";
import { CommonModule, DatePipe } from "@angular/common";
import { NgbModule, NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DialogComponent } from "./component/dialog/dialog.component";
import { DatePickerComponent } from "./component/datepicker/date-picker.component";
import { BasicTableComponent } from "./component/basic-table/basic-table.component";
import { FormComponent } from "./component/form/form.component";
import { RemoveUnderscorePipe } from "./pipes/remove-underscore.pipe";
import { IDUpperCasePipe } from "./pipes/id-uppercase.pipe";
import { TimeAgoPipe } from 'time-ago-pipe';
import { TimeagoModule } from 'ngx-timeago';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { MillisToTimeAgoPipe } from "./pipes/millis-to-time-ago.pipe";
import { NgScrollbarModule, NgScrollbar } from "ngx-scrollbar";
import { ConfirmModalComponent } from './component/form/confirm-modal/confirm-modal.component';
import { DragndropListComponent } from './component/form/dragndrop-list/dragndrop-list.component';
import { AutoCompleteDropdownComponent } from "./component/linked-list-dropdown/auto-complete-dropdown/auto-complete-dropdown.component";
import { MultiTierDropdownComponent } from "./component/linked-list-dropdown/multi-tier-dropdown/multi-tier-dropdown.component";
import { LinkedListDropdownComponent } from "./component/linked-list-dropdown/linked-list-dropdown.component";
import { UploadComponent } from "./component/upload/upload.component";
import { HideDirective } from "./component/form/directives/hide.directive";
import { NativeElementInjectorDirective } from "./component/form/directives/nativeelementinjector.directive";
import { FileUploadModule } from "ng2-file-upload";
import { ImageViewerComponent } from "./component/image-viewer/image-viewer.component";
import { DynamicCardComponent } from "./customers/modal/customer-details/components/dynamic-card/dynamic-card.component";
import { AdvancedSearchComponent } from "./approvals/advanced-search/advanced-search.component";
import { TimerComponent } from "./component/timer/timer-component";
// tslint:disable-next-line: max-line-length
import { RegInfoApprovalInfoComponent } from "./customers/modal/customer-details/components/reg-info-approval-info/reg-info-approval-info.component";
import { RejectionConfirmModalComponent } from "./approvals/rejection-modal-confirm/rejection-modal-confirm.component";
import { RejectionModalComponent } from "./approvals/rejection/rejection-modal.component";
import { InputModalComponent } from "./component/form/input-modal/input-modal.component";
import { RejectionImageViewerComponent } from "./component/rejection-image-viewer/rejection-image-viewer.component";
import { OriginalImageDialogComponent } from "./component/original-image-dialog/original-image-dialog.component";
import { TextSnipperPipe } from "./pipes/text-snipper.pipe";
import { UserIdleModule } from 'angular-user-idle';
import { InfoDialogComponent } from "./component/info-dialog/info-dialog.component";
import { GoogleChartsModule } from 'angular-google-charts';
// import { GaugeChartModule } from 'angular-gauge-chart';
import { ChartTestComponent } from "./apps/test/google-charts/chart-test.component";
import { AxonChartComponent } from "./component/dashboard/axon-chart.component";
import { AxonGaugeComponent } from "./component/dashboard/axon-gauge.component";
import { TableComponent } from "./component/table/table.component";
import { TableTestComponent } from "./apps/test/table/tabletest.component";
import { MaterialTableTestComponent } from "./apps/test/material-table/material-table-test.component";
import { AxonBlockComponent } from "./component/dashboard/axon-block.component";
import { SystemDashboardComponent } from "./dashboards/system/system-dashboard.component";
import { DragScrollModule } from 'ngx-drag-scroll';
import { SecuredImagePipe } from "./pipes/secured-img.pipe";
import { RouterModule } from "@angular/router";
import { RegistrationLocationComponent } from "./customers/modal/customer-details/components/registration-location/registration-location.component";
import { AddressComponent } from "./customers/modal/customer-details/components/address/address.component";
import { CustomerModalComponent } from "./customers/modal/customer-modal.component";
import { CustomerDetailsComponent } from "./customers/modal/customer-details/customer-details.component";
import { RegistrationHistoryComponent } from "./customers/modal/registration-history/registration-history.component";
import { SubmissionHistoryComponent } from "./customers/modal/submission-history/submission-history.component";
import { UnauthorizedComponent } from "./component/unauthorized/unauthorized.component";
import { DownloadPdfComponent } from "./customers/modal/download-pdf/download-pdf.component";
import { ChurnFileUploadComponent } from "./services/churn/churn-file-upload.component";
import { PasswordInputComponent } from "./component/passwordinput/password-input.component";
import { RawFileUploadComponent } from "./component/raw-file-uplad/raw-file-upload.component";
import { QRGeneratorFormComponent } from "./apps/test/qr-generator-form/qr-generator-form.component";
import { QRCodeModule } from 'angularx-qrcode';
import { CustomerModalHistoricComponent } from "./customers/modal/registration-history/customer-modal-historic.component";
import { ArchiveDataComponent } from "./customers/modal/archive-data/archive-data.component";
import { MultiscanViewerComponent } from "./component/image-viewer/multiscan-viewer.component";
import { DigitOnlyDirective } from "./directives/digit-only.directive";
import { MaskDirective } from "./directives/mask.directive";
import { MomentDateAdapter, MomentDateModule } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material";
import { CustomerViewDetailsComponent } from "./customers/customer-view-details/customer-view-details.component";
import { SignaturePad } from "./shared/signature-pad.component";
import { Form2Component } from "./component/form2/form2.component";
import { AddButtonComponent } from "./component/form2/add-button/add-button.component";
import { DisplayTermsConditionsSigning } from "./customers/modal/terms-and-conditions/display-terms-conditions-signing.component";
import { TermsConditionsSignature } from "./customers/modal/terms-and-conditions/terms-conditions-signature.component";
import { ConfirmDialogComponent } from "./component/confirm-dialog/confirm-dialog.component";
import { ConfirmDialog2Component } from "./component/confirm-dialog-2/confirm-dialog-2.component";
import { DynamicTextComponent } from "./component/dynamic-add-component/dynamic-text-component/dynamic-text-component";
import { DynamicImageUploadComponent } from "./component/dynamic-add-component/dynamic-image-component/customer-images/dynamic-image-upload/dynamic-image-upload.component";
import { DynamicImageComponent } from "./component/dynamic-add-component/dynamic-image-component/customer-images/dynamic-images.component";
import { DynamicAddComponent } from "./component/dynamic-add-component/dynamic-add-component";
import { HighchartsGraphComponent } from "./component/highcharts-graph/highcharts-graph.component";
import { ReportDisplayTableComponent } from "./report/components/report-display-table/report-display-table.component";
import { ReportDisplayColumnComponent } from "./report/components/report-display-column/report-display-column.component";
import { ReportDisplayPieComponent } from "./report/components/report-display-pie/report-display-pie.component";
import { ReportDisplayHeatmapComponent } from "./report/components/report-display-heatmap/report-display-heatmap.component";
import { ReportDisplayColumnLineComponent } from "./report/components/report-display-column-line/report-display-column-line.component";
import { JwPaginationComponent, JwPaginationModule } from "jw-angular-pagination";
import { AxonSpinnerComponent } from "./component/spinner/spinner.component";
import { TreeComponent } from "./component/tree/tree.component";
import { DmsNavBarComponent } from "./dms/components/dms-nav-bar/dms-nav-bar.component";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DmsBreadcrumbsComponent } from "./dms/components/dms-breadcrumbs/dms-breadcrumbs.component";
import { NgxSpinnerModule } from 'ngx-spinner';
import { CheckedPdfDownloadLinkComponent } from "./component/material-table/components/checked-pdf-download-link/checked-pdf-download-link.component";
import { PasswordResetModalComponent } from "./authentication/password-reset/password-reset.component";
import { ReportParameterFilterDateComponent } from "./report/components/report-parameter-filter-date/report-parameter-filter-date.component";
import { ReportParameterFilterComboComponent } from "./report/components/report-parameter-filter-combo/report-parameter-filter-combo.component";
import { ReportParameterFilterTextComponent } from "./report/components/report-parameter-filter-text/report-parameter-filter-text.component";
import { ReportParameterFilterOrganizationComponent } from "./report/components/report-parameter-filter-organization/report-parameter-filter-organization.component";
import { SignatureComponent } from "./customers/modal/signature/signature.component";
import { SignatureBoxComponent } from "./customers/modal/signature/signature-box.component";
import { AgentBlacklistComponent } from "./agents/modal/agent-blacklist/agent-blacklist.component";
import { CustomerEditImagesComponent } from "./customers/customer-edit-images/customer-edit-images.component";

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL'
    },
    display: {
        dateInput: 'DD-MM-YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY'
    }
};

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        CommonModule,
        NgbModalModule,
        AxonMaterialModule,
        NgScrollbarModule,
        TimeagoModule.forRoot(),
        FileUploadModule,
        NgxImageZoomModule.forRoot(),
        UserIdleModule.forRoot({idle: 600, timeout: 300, ping: 120}),
        //GoogleChartsModule.forRoot('my-custom-maps-api-key or not', 'chart-version'),
        GoogleChartsModule.forRoot(),
        // GaugeChartModule,
        DragScrollModule,
        RouterModule,
        QRCodeModule,
		MomentDateModule,
		JwPaginationModule,
		FontAwesomeModule,
		NgxSpinnerModule,
    ],
    exports: [
        MaterialTableComponent,
        DialogComponent,
        DatePickerComponent,
        BasicTableComponent,
        FormComponent,
		Form2Component,
        RemoveUnderscorePipe,
        IDUpperCasePipe,
        MillisToTimeAgoPipe,
        TextSnipperPipe,
        SecuredImagePipe,
        TimeagoModule,
        DragndropListComponent,
        NgScrollbar,
        UploadComponent,
        RawFileUploadComponent,
        HideDirective,
        ImageViewerComponent,
        MultiscanViewerComponent,
        RejectionImageViewerComponent,
        DynamicCardComponent,
        NativeElementInjectorDirective,
        TimerComponent,
        RegInfoApprovalInfoComponent,
        PasswordInputComponent,
        RegistrationLocationComponent,
		LinkedListDropdownComponent,
		SecuredImgComponent,
		AddressComponent,
		DigitOnlyDirective,
		MaskDirective,
		CheckedPdfDownloadLinkComponent,
		CustomerViewDetailsComponent,
		CustomerEditImagesComponent,
		SignaturePad,
		AddButtonComponent,
		DisplayTermsConditionsSigning,
		TermsConditionsSignature,
		SignatureBoxComponent,
		SignatureComponent,
		ConfirmDialogComponent,
		ConfirmDialog2Component,
		DynamicAddComponent,
		DynamicTextComponent,
		DynamicImageComponent,
		DynamicImageUploadComponent,
		HighchartsGraphComponent,
		ReportDisplayTableComponent,
		ReportDisplayColumnComponent,
		ReportDisplayPieComponent,
		ReportDisplayHeatmapComponent,
		ReportDisplayColumnLineComponent,
		JwPaginationComponent,
		AxonSpinnerComponent,
		TreeComponent,
		DmsNavBarComponent,
		DmsBreadcrumbsComponent,
		NgxSpinnerModule,
		ReportParameterFilterDateComponent,
		ReportParameterFilterComboComponent,
		ReportParameterFilterTextComponent,
		ReportParameterFilterOrganizationComponent,
		AgentBlacklistComponent,
    ],
    declarations: [
        MaterialTableComponent,
        DialogComponent,
        DatePickerComponent,
        BasicTableComponent,
        FormComponent,
		Form2Component,
        RemoveUnderscorePipe,
        MillisToTimeAgoPipe,
        TextSnipperPipe,
        IDUpperCasePipe,
        SecuredImagePipe,
        DragndropListComponent,
        ConfirmModalComponent,
        RejectionConfirmModalComponent,
        InputModalComponent,
		PasswordResetModalComponent,
        OriginalImageDialogComponent,
        AutoCompleteDropdownComponent,
        RejectionModalComponent,
        MultiTierDropdownComponent,
        LinkedListDropdownComponent,
        UploadComponent,
        RawFileUploadComponent,
        HideDirective,
        ImageViewerComponent,
        MultiscanViewerComponent,
        RejectionImageViewerComponent,
        DynamicCardComponent,
        NativeElementInjectorDirective,
        AdvancedSearchComponent,
        TimerComponent,
        RegInfoApprovalInfoComponent,
        TimeAgoPipe,
        InfoDialogComponent,
        ChartTestComponent,
        QRGeneratorFormComponent,
        AxonChartComponent,
        AxonGaugeComponent,
        AxonBlockComponent,
        TableComponent,
        TableTestComponent,
        MaterialTableTestComponent,
        SystemDashboardComponent,
        RegistrationLocationComponent,
        AddressComponent,
        CustomerModalComponent,
        CustomerModalHistoricComponent,
        CustomerDetailsComponent,
        RegistrationHistoryComponent,
        SubmissionHistoryComponent,
        ArchiveDataComponent,
        UnauthorizedComponent,
        DownloadPdfComponent,
        ChurnFileUploadComponent,
		PasswordInputComponent,
		SecuredImgComponent,
		DigitOnlyDirective,
		MaskDirective,
		CheckedPdfDownloadLinkComponent,
		CustomerViewDetailsComponent,
		CustomerEditImagesComponent,
		SignaturePad,
		AddButtonComponent,
		DisplayTermsConditionsSigning,
		TermsConditionsSignature,
		SignatureBoxComponent,
		SignatureComponent,
		ConfirmDialogComponent,
		ConfirmDialog2Component,
		DynamicAddComponent,
		DynamicTextComponent,
		DynamicImageComponent,
		DynamicImageUploadComponent,
		HighchartsGraphComponent,
		ReportDisplayTableComponent,
		ReportDisplayColumnComponent,
		ReportDisplayPieComponent,
		ReportDisplayHeatmapComponent,
		ReportDisplayColumnLineComponent,
		AxonSpinnerComponent,
		TreeComponent,
		DmsNavBarComponent,
		DmsBreadcrumbsComponent,
		ReportParameterFilterDateComponent,
		ReportParameterFilterComboComponent,
		ReportParameterFilterTextComponent,
		ReportParameterFilterOrganizationComponent,
		AgentBlacklistComponent,
    ],
    providers: [
        DatePipe,
        CustomerModalHistoricComponent,
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
    entryComponents: [
        ConfirmModalComponent, InfoDialogComponent, RejectionConfirmModalComponent, InputModalComponent,PasswordResetModalComponent,
        AdvancedSearchComponent, AutoCompleteDropdownComponent, RejectionModalComponent,
        OriginalImageDialogComponent, ChurnFileUploadComponent, CustomerModalHistoricComponent,
		DisplayTermsConditionsSigning,
		TermsConditionsSignature,
		SignatureBoxComponent,
		SignatureComponent,ConfirmDialogComponent,ConfirmDialog2Component,
		DynamicImageUploadComponent,AxonSpinnerComponent, AgentBlacklistComponent
    ]
})
/**
 * Components can be re-used across the GUI, but cannot be declared in multiple places.
 * Therefore, the AxonSharedModule will be used to import, export and declare commonly used
 * components, and the components requiring these common components, can import the AxonSharedModule.
 */
export class AxonSharedModule {}
