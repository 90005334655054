import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "../auth/auth.service";
import {
	DataRequest,
	DataResponse,
	Msisdn,
	DynamicLink,
	FicaOrganization,
	SelfRegDataDto,
	SelfRegImage,
	Token,
	EFlow,
	CustomerTracingCoordinate,
	ImagesReport,
} from "../../dto/dtos";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AxonService } from "../axon-service";
import { FilterOption } from "../../component/material-table/material-table.component";
import { AxonUtils } from "../../utils/axon-utils";
import { HttpDataService } from "../http/http-data.service";
import { Notifier } from "../../utils/notifier";
import { Section } from "../../utils/constants";
import { SectionUtils } from "../../../environments/environment";
import { AbstractHttpService } from "../http/http-abstract-service";

@Injectable({
	providedIn: "root",
})
/**
 * Entry point for components requiring access to customer data
 */
export class CustomerService extends AxonService {
	private httpService: HttpDataService;

	constructor(_httpClient: HttpClient, _authService: AuthService, _notifier: Notifier) {
		super(_httpClient, _authService, _notifier);
	}

	/**
	 * Fetches customers for table data
	 * @param pageNum
	 * @param pageMax
	 * @param filterOptions
	 */
	public getCustomers(pageNum: number, pageMax: number, filterOptions: FilterOption[], dmsId: number): Observable<DataResponse> {
		const dataRequest: DataRequest = {
			pageNum: pageNum,
			pageMax: pageMax,
			filterOptions: filterOptions,
			dmsNodeId: dmsId,
		};

		return this.getDataResponse(dataRequest, "/getcustomers");
	}

	public getImagesReport(startDate: number, endDate: number): Observable<ImagesReport[]> {
		this.httpService = new HttpDataService(this.httpClient, this.authService, "/jupiter", this.notifier);
		return this.httpService.doGet<Array<ImagesReport>>(`/images/report/${startDate}/${endDate}`);		
	}

	public getTracingData(axonId: number, start: string, end: string): Observable<CustomerTracingCoordinate[]> {
		const httpService = new HttpDataService(this.httpClient, this.authService, "/jupiter", this.notifier);
		return httpService.doGet(`/${axonId}/tracing/${start}/${end}`);
	}

	public getEflowData(axonId: number): Observable<Array<EFlow>> {
		this.httpService = new HttpDataService(this.httpClient, this.authService, "/jupiter", this.notifier);
		return this.httpService.doGet<Array<EFlow>>(`/eflow/${axonId}`);
	}

	/**
	 * Fetches invidiual customer by axon ID
	 * @param axonId
	 */
	public getCustomer(axonId: number): Observable<DataResponse> {
		const dataRequest: DataRequest = {
			identifierNum: axonId,
		};

		return this.getDataResponse(dataRequest, "/getcustomer");
	}

	/**
	 * Fetches invidiual customer by pinref
	 * @param axonId
	 */
	public getCustomerHistoric(pinref: number): Observable<DataResponse> {
		const dataRequest: DataRequest = {
			identifierNum: pinref,
		};

		return this.getDataResponse(dataRequest, "/getcustomerhistoric");
	}

	/**
	 * Fetches customer's submissions for table data
	 * @param axonId
	 * @param pageNum
	 * @param pageMax
	 * @param filterOptions
	 */
	public getCustomerSubmissions(axonId: number, pageNum: number, pageMax: number, filterOptions: FilterOption[]): Observable<DataResponse> {
		const dataRequest: DataRequest = {
			identifierNum: axonId,
			pageNum: pageNum,
			pageMax: pageMax,
			filterOptions: filterOptions,
		};

		return this.getDataResponse(dataRequest, "/getcustomersubmissions");
	}

	/**
	 * Fetches the customer's archive data showing a churn history for their MSISDN
	 * @param msisdn
	 */
	public getArchiveData(msisdn: string): Observable<DataResponse> {
		const dataRequest: DataRequest = {
			identifierStr: msisdn,
		};

		return this.getDataResponse(dataRequest, "/getarchivedata");
	}

	/**
	 * Fetches customer's registration history for table data
	 * @param axonId
	 * @param pageNum
	 * @param pageMax
	 * @param filterOptions
	 */
	public getRegistrationHistory(axonId: number, pageNum: number, pageMax: number, filterOptions: FilterOption[]): Observable<DataResponse> {
		const dataRequest: DataRequest = {
			identifierNum: axonId,
			pageNum: pageNum,
			pageMax: pageMax,
			filterOptions: filterOptions,
		};

		return this.getDataResponse(dataRequest, "/getcustomerreghistory");
	}

	/**
	 * Fetches a single customer by Axon ID for dynamic data.
	 * @param path
	 * @param identifierNum
	 */
	public getCustomerDynamicDataByAxonId(sectionId: number, identifierNum: number, cardId: number): Observable<DataResponse> {
		const dataRequest: DataRequest = {
			sectionId: sectionId,
			cardId: cardId,
			identifierNum: identifierNum,
		};

		return this.getDataResponse(dataRequest, "/axonid", "/dynamicdata");
	}

	/**
	 * Compiles and fetches filled out pdf
	 * @param customerId
	 */
	public getPDFFormFilled(customerId: number): Observable<Blob> {
		const dataRequest: DataRequest = {
			identifierNum: +customerId,
		};

		this.httpService = new HttpDataService(this.httpClient, this.authService, "/customer", this.notifier);

		//Endpoint was /ecafcustomerform
		return this.httpService.getBlob(dataRequest, "/pdfcustomerform").pipe(
			map((response) => {
				return response;
			})
		);
	}

	/**
	 * Compiles and fetches filled out pdf - historic
	 * @param customerId
	 */
	public getPDFFormFilledHistoric(pinref: number): Observable<Blob> {
		const dataRequest: DataRequest = {
			pinref: +pinref,
		};

		this.httpService = new HttpDataService(this.httpClient, this.authService, "/customer", this.notifier);

		//End point '/ecafhistoriccustomerform
		return this.httpService.getBlob(dataRequest, "/pdfhistoriccustomerform").pipe(
			map((response) => {
				return response;
			})
		);
	}

	/**
	 * Sends section ID and DynamicLink field info to the server for persistence
	 * @param sectionId
	 * @param axonId
	 * @param dynamicLink
	 */
	public saveFormData(section: Section, axonId: number, dynamicLink: DynamicLink): Observable<DataResponse> {
		const dataRequest: DataRequest = {
			sectionId: SectionUtils.getSectionId(section),
			identifierNum: axonId,
			dynamicLink: dynamicLink,
		};

		return this.getDataResponse(dataRequest, "/save", "/dynamicdata");
	}

	/**
	 * Sends add sim request
	 * @param sectionId
	 * @param axonId
	 * @param dynamicLink
	 */
	public saveAddSIM(
		axonId: number,
		msisdns: Array<Msisdn>,
		dynamicLink: DynamicLink,
		ficaOrganization: FicaOrganization
	): Observable<DataResponse> {
		const dataRequest: DataRequest = {
			sectionId: dynamicLink.sectionId,
			identifierNum: axonId,
			msisdns: msisdns,
			dynamicLink: dynamicLink,
			ficaOrganization: ficaOrganization,
		};

		return this.getDataResponse(dataRequest, "/save", "/dynamicdata");
	}

	public saveStandardAddSIM(axonId: number, msisdns: Array<Msisdn>, dynamicLink: DynamicLink): Observable<DataResponse> {
		const dataRequest: DataRequest = {
			sectionId: dynamicLink.sectionId,
			identifierNum: axonId,
			msisdns: msisdns,
			dynamicLink: dynamicLink,
		};

		return this.getDataResponse(dataRequest, "/save", "/dynamicdata");
	}

	/**
	 * Sends section ID and DynamicLink field for new customer creation
	 * @param sectionId
	 * @param axonId
	 * @param dynamicLink
	 */
	public addCustomer(sectionId: number, msisdns: Array<Msisdn>, dynamicLink: DynamicLink): Observable<DataResponse> {
		const dataRequest: DataRequest = {
			sectionId: sectionId,
			msisdns: msisdns,
			dynamicLink: dynamicLink,
		};

		return this.getDataResponse(dataRequest, "/addcustomer", "/dynamicdata");
	}

	public getSelfRegData(token: string, pin: string): Observable<SelfRegDataDto> {
		let headers = new HttpHeaders();
		headers = headers.append("pin", pin);
		const httpService = new HttpDataService(this.httpClient, this.authService, "/selfreg", this.notifier);
		return httpService.doGetWithHeader(`/axdas/${token}/get`, headers);
	}

	public getSelfRegImage(token: string, pin: string, imageId: number): Observable<SelfRegImage> {
		let headers = new HttpHeaders();
		headers = headers.append("pin", pin);
		const httpService = new HttpDataService(this.httpClient, this.authService, "/selfreg", this.notifier);
		return httpService.doGetWithHeader(`/axdas/${token}/getimage/${imageId}`, headers);
	}

	/**
	 * Sends section ID and DynamicLink field info to the server for persistence
	 * @param sectionId
	 * @param axonId
	 * @param dynamicLink
	 */
	public rotateImage(imageId: number, degrees: number): Observable<DataResponse> {
		return this.getDataResponse({}, `/rotate_image/${imageId}/${degrees}`, "/customer");
	}

	/**
	 * Send the encrypted contents of a file as a string to be handled by the server
	 */
	public decryptExportedFile(frmData: FormData): Observable<any> {
		return this.getFileResponse(frmData, "/uploadrecords");
	}

	public emailDocument(frmData: FormData): Observable<any> {
		return this.getFileResponse(frmData, "/emailpdf");
	}

	public doBulkEdit(dataRequest: DataRequest): Observable<DataResponse> {
		return this.getDataResponse(dataRequest, "/bulkEdit", "/customer");
	}

	private getFileResponse(formData: FormData, subpath: string): Observable<DataResponse> {
		const rootpath = "/customer";
		this.httpService = new HttpDataService(this.httpClient, this.authService, rootpath, this.notifier);
		return this.httpService.sendFile(formData, subpath).pipe(
			map((response) => {
				return response;
			})
		);
	}

	private blobToDataURL(blob, callback) {
		var a = new FileReader();
		a.onload = function (e: any) {
			callback(e.target.result);
		};
		a.readAsDataURL(blob);
	}

	public downloadZipFile(axonId: number, finishedCallBack: any): Observable<any> {
		const authToken: Token = this.authService.getAuthToken();
		const token: string = authToken === null ? null : authToken.token;

		const agentId: string = this.authService.getAuthAgent().agentId.toString();
		let headers = new HttpHeaders();
		headers = headers.append("axon-token", token);
		headers = headers.append("axon-agent-id", agentId);
		headers = headers.append("Content-Type", "application/zip");
		headers = headers.append("Accept", "application/zip");
		headers = headers.append("Cache-control", "no-cache no-store");
		headers = headers.append("chunked", "true");
		headers = headers.append("timeout", "360000");
		headers = headers.append("Expires", "0");
		headers = headers.append("Pragma", "no-cache");

		let url = `${AbstractHttpService.BASE_URL}/jupiter/info/download/${axonId}`;

		console.log("Downloading file from ", url, " with headers ", headers);

		const returnPromise = this.httpClient.get(url, {
			headers: headers,
			responseType: "blob",
		});

		console.log("Promise created");
		returnPromise
			.toPromise()
			.then((data) => {
				console.log("File downloaded successfully");
				// const thefile = new Blob([data], { type: "application/zip" });
				this.blobToDataURL(data, (dataUrl) => {
					var a = document.createElement("a");
					document.body.appendChild(a);
					a.setAttribute("style", "display: none");
					a.href = dataUrl;
					a.download = `axon-${axonId}.zip`;
					a.click();
					a.remove();
					finishedCallBack();
				});
			})
			.catch((error) => {
				console.log("Error downloading the file.");
				console.log(error);
				this.notifier.error("Error downloading the file.");
				finishedCallBack();
			});

		return returnPromise;
	}

	/**
	 * Helper to get the observable containing the response
	 * @param dataRequest
	 * @param subpath - the subpath following the rootpath
	 * @param rootpath - if null, will be set to /user
	 */
	public getDataResponse(dataRequest: DataRequest, subpath: string, rootpath?: string, contentType?: string): Observable<DataResponse> {
		/* If not defined, set to /customer i.e. the default root path */
		if (!rootpath) {
			rootpath = "/customer";
		}

		this.httpService = new HttpDataService(this.httpClient, this.authService, rootpath, this.notifier);
		return this.httpService.sendData(dataRequest, subpath, contentType).pipe(
			map((response) => {
				if (response.data) {
					if (response.data.birthDate) {
						response.data.birthDate = AxonUtils.getDate(response.data.birthDate);
					}

					// if (subpath === '/getcustomer') {
					//     for ( let i = 1; i < 1000; i++ ) {
					//         let msisdn: Msisdn = {
					//             msisdn: response.data.msisdns[0].msisdn,
					//             imsi: response.data.msisdns[0].imsi,
					//             iccid: i + '',
					//             activation: response.data.msisdns[0].activation,
					//             activationDatetime: response.data.msisdns[0].activationDatetime,
					//             activationError: response.data.msisdns[0].activationError
					//         };
					//         response.data.msisdns.push(msisdn);
					//     }
					// }
					// response.data.originalDatetime = '2019-04-17T00:51:30.000+0000';

					// if ( response.data.originalDatetime ) {
					//     response.data.originalDatetime = AxonUtils.getDate(response.data.originalDatetime);
					// }

					/* Work out time difference in millis between registration and approval */
					if (response.data.originalDatetime && response.data.approvedDatetime) {
						const approve: Date = new Date(response.data.approvedDatetime);
						const register: Date = new Date(response.data.originalDatetime);
						response.data.timeToApproveMillis = Math.abs(approve.getTime() - register.getTime());
					}
				}

				return response;
			})
		);
	}
}
