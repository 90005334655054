/*
For development use import * as $ from 'jquery' for intellisense.
For serving or building use declare var $:any - which loses intellisense
but resolves the issue with the error on usage of tooltip()
*/
import * as $ from 'jquery';
// declare var $: any;
import { AxonUtils } from "./utils/axon-utils";
import { DOB_START_DATE } from "./utils/constants";
import { AxonModel } from "./dto/dtos";
import { Observer } from "rxjs";
import { FormComponent } from "./component/form/form.component";
import { SystemService } from './services/system/system.service';
import { AfterViewInit } from '@angular/core';

let fullPage: boolean;

export const handleResize = function () {
	doHandleResize(false);
}

export const doHandleResize = function (isApproval) {
	console.log('Handling resize: Approval ? ', isApproval);
	const width =
		window.innerWidth > 0 ? window.innerWidth : this.screen.width;
	const topOffset = 70;

	if (width < 1170) {
		$('body').addClass('mini-sidebar');
		$('.navbar-brand span').hide();
		$('.scroll-sidebar, .slimScrollDiv')
			.css('overflow-x', 'visible')
			.parent()
			.css('overflow', 'visible');
		$('.sidebartoggler i').addClass('ti-menu');
	} else {
		$('body').removeClass('mini-sidebar');
		$('.navbar-brand span').show();
		$('.sidebartoggler i').removeClass('ti-menu');
	}

	/* Due to removing the padding-bottom: 60px on page-wrapper in pages.scss, need to reduce calculated height by 60px */
	let height =
		(window.innerHeight > 0 ? window.innerHeight : this.screen.height) - 1 - 60 - 70 - 210;

	/*
	If full page is true, add an extra 95 pixels to the total height of the page
	- example usage can be found in customer landing page, submission history and reg history pages
	*/
	if (fullPage) {
		height += 95;
	}

	if (height > topOffset) {
		if (isApproval) {
			$('.example-table-container').css('height', (height + 74) + 'px');
		} else {
			$('.example-table-container').css('height', height + 'px');
		}
	}

	const heightModalScroll =
		(window.innerHeight > 0 ? window.innerHeight : this.screen.height) - 1 - 130;
	let heightImageViewer = heightModalScroll - 20;

	let heightRejectionImageViewer;
	if (isApproval) {
		heightImageViewer = heightImageViewer - 20;
		heightRejectionImageViewer = heightImageViewer;
	}

	const approvalScrollContent = heightModalScroll - 15;
	const approvalHeight = approvalScrollContent - 10;

	const heightAgentModalTabBody = heightModalScroll - 70;

	// console.log( 'heightModalScroll: ' + heightModalScroll + ' - topOffset: ' + topOffset );

	if (heightModalScroll > topOffset) {
		$('.approval-container').css('max-height', approvalHeight + 'px');
		$('.approval-left-scroll').css('max-height', approvalHeight + 'px');
		$('.approval-scroll-content').css('max-height', approvalScrollContent + 'px');
		$('.approval-image-content').css('height', approvalHeight + 'px');
		$('.modal-scroll').css('height', heightModalScroll + 'px');
		$('.customer-left-scroll').css('height', heightModalScroll + 'px');
		// $('.image-viewer').css('height', heightImageViewer + 'px');
		$('.rejection-image-viewer').css('height', heightRejectionImageViewer + 'px');
		$('.agent-modal-tab-body').css('height', heightAgentModalTabBody + 'px');
		$('.churn-batch-scroll').css('height', heightModalScroll + 'px');
		// $('.churn-scrollable').css('height', ( heightModalScroll - 85 ) + 'px');
	}
};

export class AxonComponent implements AfterViewInit, Observer<any> {

    dobStartDate = DOB_START_DATE;
    dateChanged: boolean = false;
    child: any;
    hideGroupActions: boolean;

	showLoading = false;
	mode = "indeterminate";

	ngAfterViewInit() {
		this.initResizeListener();
	}

	initResizeListener() {
		console.log('AxonComponent:initResizeListener');
		$(window).ready(handleResize);
		$(window).on('resize', handleResize);
	}

	initApprovalResizeListener() {
		doHandleResize(true);
	}

	sanitise(url: string) {
		// console.log('url: ' + url);
		return AxonUtils.domSanitizer.bypassSecurityTrustUrl(url);
	}

	sanitiseResource(url: string) {
		// console.log('url: ' + url);
		return AxonUtils.domSanitizer.bypassSecurityTrustResourceUrl(url);
	}

	sayHello(): string {
		return 'I am the Axon Component';
	}

	getErrorList(errorObject) {
		if (errorObject) {
			console.log('Getting error list for [' + JSON.stringify(errorObject) + ']');
			return Object.keys(errorObject);
		}
	}

	/**
	 * Child components needing to know how many rows have been checked in the material table
	 * (if it's using material table) need to override this method.
	 *
	 * MaterialTableComponent will send the check count to the child component via this overriden method.
	 * @param selectedCheckboxes
	 */
	setCheckboxState(selectedCheckboxes: AxonModel[]) { }

	/**
	 * If any child components require notification of data being saved to the server.
	 * For example, a component displays a form, which submits data to the server for saving.
	 * The component may want to be notified that the save has occurred successfully, so it
	 * may reload data.
	 */
	saveSuccessful(value?: any) { }
	showError(data?: any): boolean { return false; }

	refresh() { }

	addButtonAddItem(currentList, cardName, inputValue): any[] { return []; }

	addButtonDeleteItem(currentList, cardName, inputValue): any[] { return []; }

	getAddedItemsList(cardName): any[] { return []; }

	setAddedItemsList(cardName, list: any[]) { }

	formInputsChanged(state: Map<string, string>) { }

	dragNDropUpdated() { }

	validateDragndrop() { }

	/* Observable methods for form changes - connected in form.component.ts */
	next(value) { }
	complete(): void { }
	error(error) { }

	pickedAddress(mapSuggestion) { }

	/**
	 * If called with true, the page height will be increased by 95 pixels.
	 * @param isFullPage
	 */
	setFullPage(isFullPage: boolean) {
		fullPage = isFullPage;
		handleResize();
	}


	/* Allows children to override and provide own implementation of getting a value from component */
	getValue(): any { }

	/* Allows children to override and provide own implementation of displaying object details such as in a modal */
	showDetails(obj: any) { }

	/* Don't show 'Invalid Date' for null dates or anything doesn't translate to a date */
	getValidDateString(value: any) {
		if (isNaN(new Date(value).valueOf())) {
			return '';
		} else {
			return new Date(value).toLocaleString();
		}
	}

	/* Don't show value [0] on fields agent ID where we know 0 never happens(hopefully) */
	getValidNumber(value: any) {
		if (value == 0 || isNaN(value)) {
			return '';
		} else {
			return value;
		}
	}

	/* Allows children to override and provide own implementation of date change handling */
	handleDateChange(obj: any, newDate: Date) { }

	/* Allows children to override and provide own implementation of button click event handling */
	handleButtonClick(row: any, label: string) { }

	/* Allows children to override and provide own implementation of checkbox click */
	handleCheckboxClick(row: any) { }

	/* Allows children to override and provide own implementation of handling save for multiple different forms in one component */
	save(formComponent: FormComponent) { }
	
	async getPin(systemsService: SystemService) {
		return await systemsService.getProtectionPin();
	}
}
