import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { Customer, Image } from '../../dto/dtos';
import imageToBase64 from 'image-to-base64/browser';
import { DomSanitizer } from '@angular/platform-browser';
import { AllowedImageTypesForImageEdit } from '../../../environments/environment';

@Component({
	selector: 'app-customer-edit-images',
	templateUrl: './customer-edit-images.component.html',
	styleUrls: ['./customer-edit-images.component.scss']
})
export class CustomerEditImagesComponent implements OnInit {
	@Input() customer: Customer;
	@Output() onRefreshCustomer = new EventEmitter<Customer>();

	images: Image[];

	constructor(private domSanitizer: DomSanitizer){}

	ngOnInit(): void {

		this.images = [];
		this.customer.images.forEach(item => {
			this.images.push(item);
		});

		AllowedImageTypesForImageEdit.forEach(allowedImageType => {
			var image = this.images.filter(image => image.type === allowedImageType)[0];
			if (!image) {
				imageToBase64('../../assets/images/placeholder-img.jpeg') // Path to the image
				.then(
					(imgData) => {

						const image: Image = {
							id: 0,
							type: allowedImageType,
							image: "data:image/png;base64," + imgData,
							size: 0,
							ext: "png",
							thumbImageUrl: "data:image/png;base64," + imgData,
							thumbSafeImageUrl: this.domSanitizer.bypassSecurityTrustUrl("data:image/png;base64," + imgData),
							imageUrl: "data:image/png;base64," + imgData,
							safeImageUrl: this.domSanitizer.bypassSecurityTrustUrl("data:image/png;base64," + imgData),
							isPlaceholder: true,
							multiscanImages: [],
						};

						this.images.push(image);
					}
				)
				.catch(
					(error) => {
						console.log(error); // Logs an error if there was one
					}
				)
			}
		});

		
	}

	refreshCustomer(customer: Customer) {
		this.onRefreshCustomer.emit(customer);
		this.customer = customer;
		this.ngOnInit();
	}

}
