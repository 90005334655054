import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { Customer, Msisdn, DynamicCard, DynamicLink, DynamicField, RegInfoApprovalData, FormDataDto, DataRequest } from '../../dto/dtos';
// import { simColumns as SIM_COLUMNS, cardColumns as CARD_COLUMNS, SimTableColumn, CardTableColumn } from './customer-view-details.definitions';
import { Exporter } from '../../shared/file-exporter/exporter.component';
import { CustomerService } from '../../services/customers/customer.service';
import { Notifier } from '../../utils/notifier';
import { ProfileType, ContractType, Section, Permission, DynamicInputField } from '../../utils/constants';
import { ServerError } from '../../utils/server-errors';
import { AxonUtils } from '../../utils/axon-utils';
import { environment, SectionUtils, CustomerViewDetails } from '../../../environments/environment';
import { DynamicFieldManager } from '../../settings/dynamic-fields/dynamic-field-manager';
import { CardTableColumn, SimTableColumn } from '../../model/models';
import { AuthService } from '../../services/auth/auth.service';
import { AppComponent } from '../../app.component';
import { LinkedListDropdownComponent } from '../../component/linked-list-dropdown/linked-list-dropdown.component';

@Component({
	selector: 'app-customer-view-details',
	templateUrl: './customer-view-details.component.html',
	styleUrls: ['./customer-view-details.component.scss']
})
export class CustomerViewDetailsComponent implements OnInit, OnChanges {
	@Input() inputCustomer: Customer;
	@Input() historic = false;
	@Output() onRefreshCustomer = new EventEmitter<Customer>();

	simColumns: SimTableColumn[];
	cardColumns: CardTableColumn[];

	private regInfoApprovalData: RegInfoApprovalData;

	isLoading = false;
	loadingReason: "Saving" | "Loading" = "Loading";
	customer: Customer;
	private dynamicLink: DynamicLink;
	private exporter: Exporter = new Exporter();

	private formDataDtos: Array<FormDataDto>;
	private firstFormDataDto: FormDataDto;
	private secondFormDataDto: FormDataDto;

	private closeEdit: EventEmitter<boolean> = new EventEmitter();

	showSimsCard = false;

	constructor(private customerService: CustomerService, private notifier: Notifier, private dynamicFieldMgr: DynamicFieldManager,
		private authService: AuthService, private appComponent: AppComponent) {
	}
	ngOnInit() {
		this.showSimsCard = this.environment.show_sims_card_in_customer;
		this.simColumns = CustomerViewDetails.getSimTableColumns();
		this.cardColumns = CustomerViewDetails.getCardTableColumns();
		this.customer = { ...this.inputCustomer };	//Clone inputCustomer - We should make these sort of things immutable!!!!
		this.isLoading = true;
		this.loadingReason = "Loading"

		this.setRegInfoApprovalData();

		this.fetchCustomer(false);
	}
	ngOnChanges() {
		this.ngOnInit();
	}

	fetchCustomer(reloadCustomer: boolean) {
		this.customerService.getCustomerDynamicDataByAxonId(SectionUtils.getSectionId(this.section), this.customer.axonId, 0).subscribe(response => {
			try {
				if (!response.success) {
					ServerError.printError(response);
					this.notifier.error(ServerError.formatError(response));
					return;
				}

				this.dynamicLink = response.data;
				console.log("Dynamic Link Data: ",this.dynamicLink);

				if (this.customer.rejectedFields) {
					console.log("Assigning rejected reasons to fields");

					for (const rejectedField of this.customer.rejectedFields) {
						if ( rejectedField ) {
							let field: DynamicField = AxonUtils.getFieldById(rejectedField.fieldId, this.dynamicLink);
							if ( field ) {
								field.rejectionReason = rejectedField.fieldReason;
								console.log("Field ID [" + field.id + "] - field [" + field.field + "] rejection reason [" + field.rejectionReason + "]");
							} else {
								/* Images wont be included in this.dynamicLink. So fetch the field manually */
								let localDynamicLink = this.dynamicFieldMgr.getFieldsBySectionId(SectionUtils.getSectionId(this.section));
								field = AxonUtils.getFieldById(rejectedField.fieldId, localDynamicLink);
							}
							/* Attach the name of the field so it can be displayed in the popup */
							rejectedField.field = field.field;
						}
					}
				}

				this.buildCards();

				if ( reloadCustomer ) {
					this.customerService.getCustomer(this.customer.axonId).subscribe(customerResponse => {
						this.customer = customerResponse.data;
						this.onRefreshCustomer.emit(this.customer);

						this.setRegInfoApprovalData();
						console.log('this.regInfoApprovalData: ', this.regInfoApprovalData);
					});
				}

				this.appComponent.initResizeListener();

			} finally {
				this.isLoading = false;
			}
		});
	}

	private setRegInfoApprovalData() {
		this.regInfoApprovalData = {
			axonId: this.customer.axonId,
			approvalStatus: this.customer.approvalStatus,
			approvedNote: this.customer.approvedNote,
			approvedDatetime: this.customer.approvedDatetime,
			originalDatetime: this.customer.originalDatetime,
			cards: this.customer.cards,
			rejectedFields: this.customer.rejectedFields,
			captureDeviceId: this.customer.captureDeviceId,
			captureDeviceBlacklist: this.customer.captureDeviceBlacklist,
			captureApp: this.customer.captureApp,
			captureVersion: this.customer.captureVersion,
			captureIp: this.customer.captureIp,
			recordAction: this.customer.recordAction,
			originalAgent: this.customer.originalAgent,
			captureAgent: this.customer.captureAgent,
			approvalAgent: this.customer.approvalAgent,
			viewInApprovalPath: '/approvals/view/' + this.customer.axonId,
			title: 'Customer'
		}
	}

	/**
     * Builds dynamic card / field data
     */
	private buildCards() {

		this.formDataDtos = new Array();
		for ( const card of this.dynamicLink.cards ) {		
			if ( card.type !== 'image') {
				const dynLink = new DynamicLink(this.dynamicLink);
				dynLink.cards = new Array();
				dynLink.cards[0] = card;
				this.formDataDtos.push({
					id: this.customer.axonId,
					data: dynLink
				});
			}
		}

		this.firstFormDataDto = this.formDataDtos[0];
		this.secondFormDataDto = this.formDataDtos[1];
		this.formDataDtos.splice(0, 2);
    }

	get useMsisdns() {
		return environment.use_msisdns;
	}

	get allowEdit() {

		if ( this.historic ) {
			return false;
		}

		if ( !environment.customers_allow_edit ) { 
			return false;
		}

		return AxonUtils.checkActionPermission(
			Permission.CUSTOMERS_MODIFY, this.authService, this.notifier, false
		);
	}

	get section(): Section {

		console.log('CUSTOMER:', this.customer);

		return SectionUtils.getModifyCustomerSectionIdByContractAndType(this.customer);

		// switch (this.customer.profileType.toLowerCase()) {
		// 	case ProfileType.INDIVIDUAL:
		// 		switch (this.customer.type.toLowerCase()) {
		// 			case ContractType.PREPAID:
		// 				console.log('INDIVIDUAL PREPAID SECTION');
		// 				return Section.MODIFY_PREPAID_INDIV;
		// 			case ContractType.POSTPAID:
		// 				console.log('INDIVIDUAL POSTPAID SECTION');
		// 				return Section.MODIFY_POSTPAID_INDIV;
		// 			case ContractType.HYBRID:
		// 				console.log('INDIVIDUAL HYBRID SECTION');
		// 				return Section.MODIFY_HYBRID_INDIV;
		// 		}
		// 		return undefined;
		// 	case ProfileType.CORPORATE:
		// 		switch (this.customer.type.toLowerCase()) {
		// 			case ContractType.PREPAID:
		// 				console.log('CORPORATE PREPAID SECTION');
		// 				return Section.MODIFY_PREPAID_CORP;
		// 			case ContractType.POSTPAID:
		// 				console.log('CORPORATE POSTPAID SECTION');
		// 				return Section.MODIFY_POSTPAID_CORP;
		// 			case ContractType.HYBRID:
		// 				console.log('CORPORATE HYBRID SECTION');
		// 				return Section.MODIFY_HYBRID_CORP;
		// 		}
		// 		return undefined;
		// }
	}


	exportToPDF() {
		this.exporter.dataType = 'Customer Details';
		const columns = [
			{ columnDef: 'key', header: 'Description' },
			{ columnDef: 'value', header: 'Value/Status' }
		];
		// this.exporter.showData = true;
		this.exporter.columns = columns;
		this.exporter.arrayOfArrays = this.populateCustomerDetailsTable();
		this.exporter.createTwoColumnPDF();
	}

	/**
	 * This is a verbose way to do it, but the one way to return the properties
	 * of an interface an array of strings is is very hacky
	 */
	populateCustomerDetailsTable(): any {
		const originalDateTime = this.customer.originalTime ? this.customer.originalTime : '';
		const captureAgent = this.customer.captureAgent ? this.customer.captureAgent.name + " " + this.customer.captureAgent.surname + "(" + this.customer.captureAgent.username + ")" : ''
		const approvalAgent = this.customer.approvalAgent ? this.customer.approvalAgent.name + " " + this.customer.approvalAgent.surname + "(" + this.customer.approvalAgent.username + ")" : '';
		var msisdns = "";
		for (let msisdn of this.customer.msisdns) {
			msisdns = msisdns + msisdn.msisdn + "    ";
		}
		return [
			["Axon ID", this.customer.axonId],
			["Pinref", this.customer.pinref],
			["Profile Type", this.customer.profileType],
			["Type", this.customer.type],
			["Name", this.customer.name],
			["Surname", this.customer.surname],
			["Other Name", this.customer.otherName],
			["ID", this.customer.id],
			["ID Type", this.customer.idType],
			["ID Expiry", this.customer.idExpiry],
			["Birth Date", this.customer.birthDate],
			["Birth Place", this.customer.birthPlace],
			["Title", this.customer.title],
			["Gender", this.customer.gender],
			["Address Line 1", this.customer.address1],
			["Address Line 2", this.customer.address2],
			["Address Line 3", this.customer.address3],
			["Town", this.customer.addressTown],
			["City", this.customer.addressCity],
			["Province", this.customer.addressProvince],
			["Full Address", this.customer.addressFull],
			["Address Found", this.customer.addressFound],
			["Approval Status", this.customer.approvalStatus],
			["Approval Note", this.customer.approvedNote],
			["Approval Datetime", this.customer.approvedDatetime],
			["Original Datetime", this.customer.originalDatetime],
			["Original Agent", this.customer.originalAgent.name + " " + this.customer.originalAgent.surname + "(" + this.customer.originalAgent.username + ")"],
			["Original Longitude", this.customer.originalLongitude],
			["Original Langitude", this.customer.originalLatitude],
			["Original Lat-Lng Address", this.customer.originalLatLngAddress],
			["Original Time", originalDateTime],
			["Capture Agent", captureAgent],
			["Capture Datetime", this.customer.submissionDatetime],
			["Approval Agent", approvalAgent],
			["Submission Time", this.customer.submissionTime],
			["Record Action", this.customer.recordAction],
			["Record Type", this.customer.recordType],
			["Channel", this.customer.channel],
			["Upload Status", this.customer.uploadStatus],
			["Comment", this.customer.comment],
			["MSISDN", this.customer.msisdn],

			["MSISDNs", msisdns],
			["Time to Approve", this.customer.timeToApproveMillis],

			["Rejected Fields", this.customer.rejectedFields],
			["Expiry Seconds", this.customer.expirySeconds],
			["Date Added", this.customer.dateAdded],
			["Link Expiry", this.customer.linkExpiry],
			["Approval Expired", this.customer.approvalExpired]
		];
	}

	getNameForAgent(sim: Msisdn) {
		if (sim.captureAgent !== undefined) {
			return sim.captureAgent.name + " " + sim.captureAgent.surname;
		}
	}

	save($event) {
		this.isLoading = true;
		this.loadingReason = "Saving"
		// console.log('event', $event);
		// rebuild the dynamic link
		const dynLink = new DynamicLink($event.data);
		for ( let card of dynLink.cards ) {

			for ( let field of card.fields ) {

				if (field.inputField === DynamicInputField.CHOOSER) {
					const linkedList = <LinkedListDropdownComponent> field.formComponent;
					field.formControl.setValue(linkedList.getValue());
				}

				/*
                For some reason, when attempting to JSON.stringify FormControl or FormComponent it
                results in an error:
                ERROR TypeError: Converting circular structure to JSON
                To avoid this, just set form control to null.
                */
				if (field.formControl) {
					field.value = field.formControl.value;
				}
                field.formControl = null;
                field.formComponent = null;
                field.rejectionCheckControl = null;
                field.rejectionReasonControl = null;
			}

			if ( card.id === this.firstFormDataDto.data.cards[0].id ) {
				card = this.firstFormDataDto.data.cards[0];
				continue;
			}

			if ( this.secondFormDataDto && card.id === this.secondFormDataDto.data.cards[0].id ) {
				card = this.secondFormDataDto.data.cards[0];
				continue;
			}

			for ( let formDataDto of this.formDataDtos ) {
				if ( card.id === formDataDto.data.cards[0].id ) {
					card = formDataDto.data.cards[0];
				}
			}
		}
		console.log('Updated DynamicLink with modified form data', dynLink);

		const dataRequest: DataRequest = {
			sectionId: this.dynamicLink.sectionId,
			agentId: this.authService.getAuthAgent().agentId,
			identifierNum: this.customer.axonId,
			dynamicLink: dynLink
		};

		this.customerService.getDataResponse(dataRequest, '/save', '/dynamicdata').subscribe((response) => {
			console.log('Save response', response);
			if (response.success === true) {

				this.fetchCustomer(true);
				this.notifier.info('Customer saved successfully');
				this.closeEdit.emit(true);
			} else {
				this.notifier.error(ServerError.formatError(response));
			}

			this.isLoading = false;
		});
	}

	get environment() {
		return environment;
	}

}
