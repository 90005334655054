import { Component, Input, OnChanges, OnInit, SimpleChanges, Output, EventEmitter, ViewChild } from "@angular/core";
import { DynamicCard, DynamicField, FormDataDto} from "../../../../../dto/dtos";
import { FormControl } from "@angular/forms";
import { environment, SectionUtils } from "../../../../../../environments/environment";
import { AxonUtils } from "../../../../../utils/axon-utils";
import { DynamicFieldManager } from "../../../../../settings/dynamic-fields/dynamic-field-manager";
import { Notifier } from "../../../../../utils/notifier";
import { Form2Component } from "../../../../../component/form2/form2.component";

@Component({
	selector: 'app-dynamic-card',
	templateUrl: './dynamic-card.component.html',
	styleUrls: ['./dynamic-card.component.scss']
})
export class DynamicCardComponent implements OnInit, OnChanges {

	@Input() axonId: number;
	@Input() sectionId: number;
	@Input() msisdn: string;
	@Input() recordAction: string;
	@Input() saveButtonTitle = 'Save';
    @Input() showImageUrl: boolean;
    @Input() rejectionView: boolean;
    /* Removes edit buttons if false. Used by historic customer modal */
    @Input() editable: true;
    @Input() oneColumn: false;
	@Input() isInEditMode: false;
	@Input() formDataDto: FormDataDto;
	@Input() private closeEdit: EventEmitter<boolean>;
	@Output() onSave = new EventEmitter();

	@ViewChild("axonform") form: Form2Component;

	public dynamicCard: DynamicCard;

    private editing: boolean;

    private leftList: Array<DynamicField>;
    private rightList: Array<DynamicField>;
    personalDetailsHeader: string = undefined;
    personalDetailsSubHeader: string = undefined;

	env = environment;

    constructor(
		private notifier: Notifier,
		private dynamicFieldManager: DynamicFieldManager) {
    }

    ngOnInit() {

		if (this.closeEdit) {
			this.closeEdit.subscribe(data => {
				if ( data === true ) {
					this.edit();
				}
			});
		}

		if ( this.isInEditMode ) {
			this.edit();
		}

        this.buildFields();
    }

    ngOnChanges( changes: SimpleChanges ) {
        // console.log( 'Detected changes in DynamicCardComponent. Building fields');
        this.buildFields();
    }

    /**
     * Builds the left and right list
     */
    private buildFields() {

		if ( !this.formDataDto ) {
			return;
		}
		this.dynamicCard = this.formDataDto.data.cards[0];

		if (this.dynamicCard === undefined ) {
			return;
		}

        this.personalDetailsHeader = undefined;
        
        if(this.dynamicCard && (this.dynamicCard.card === 'Personal Details' || this.dynamicCard.card === 'Corporate Details')) {
            this.personalDetailsHeader = this.recordAction;
            this.personalDetailsSubHeader = "Axon ID: " + this.axonId + " - Service No: " + this.msisdn;
        }

        this.leftList = new Array();
        this.rightList = new Array();

        const splitPoint: number = Math.ceil( this.dynamicCard.fields.length / 2 );

		console.log('Dickie: Split point: ', {
			splitPoint,
			card: this.dynamicCard
		});
        let index = 1;

        for ( const field of this.dynamicCard.fields ) {

            if ( this.rejectionView ) {
                field.rejectionCheckControl = new FormControl();
                field.rejectionReasonControl = new FormControl();
                field.showRejectionReason = false;
                field.rejectionReason = null;
            }

            if (
                (field.field === 'Handicapped' || field.field === 'Door to Door' || field.field === 'Reason') &&
                (field.value === undefined || field.value === '')
            ) {
                continue;
            }
            
            if ( index > splitPoint && !this.oneColumn ) {
                this.rightList.push( field );
            } else {
                this.leftList.push( field );
            }

            index++;
        }       
    }

	edit() {		
		if (this.formDataDto) {

			this.editing = !this.editing;
			// let section = SectionUtils.getModifyCustomerSectionIdByContractAndType(this.customer);
			// console.log('Got section [' + section + '] and ID [' + SectionUtils.getSectionId(section) + ']');
			// console.log('Got section [' + section + '] and ID [' + SectionUtils.getSectionId(section) + ']');
			let section = SectionUtils.getSectionById(this.sectionId);
			// console.log('Input sectionID = [' + this.sectionId + '] - Got section [' + section + '] and ID [' + SectionUtils.getSectionId(section) + ']');

		}
    }

    saveForm($event) {
		if ( this.isFormValid() ) {
			this.onSave.emit(this.formDataDto);
		}
    }

	isFormValid() {
		if (!this.form) {
			return true;
		}

		return this.form.isFormValid();
	}

    /**
     * Shows or hides the rejection reason input box below the field, depending
     * on the state of the checkbox
     * @param event 
     * @param id 
     */
    rejectBoxChange(event: any, id: number) {
        for ( const field of this.dynamicCard.fields ) {
            if ( field.id === id ) {
                field.showRejectionReason = event.checked;
                field.rejectionReasonControl.markAsUntouched();
                field.rejectionReasonControl.setValue(null);
                field.rejectionReason = null;
                return;
            }
        }
    }

	/*
	Chrome can handle dates in mm-dd-yyyy but firefox only works with mm/dd/yyyy
	*/
	getDate(val: any) {
		if (val !== null) {
			let date = val;
			/* Convert datetimes to date only - avoids timezone issues */
			date = new Date(AxonUtils.getDate(date));

			if ( isNaN(new Date(date).getDate())) {
				const dateStr = '' + val;
				date = Date.parse(dateStr.split('-').join('/'));
			}

			return date;
		}
		return val;
	}
}