import { Subscription } from "rxjs";
import {
	Component,
	OnInit,
	AfterViewInit,
	OnChanges,
	OnDestroy,
	Input,
} from "@angular/core";
import { Agent, AgentBlacklistDto } from "../../../dto/dtos";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AxonComponent } from "../../../axon.component";
import { AgentService } from "../../../services/agents/agent.service";
import { Observer } from "rxjs";
import { Notifier } from "../../../utils/notifier";
import { AuthService } from "../../../services/auth/auth.service";
import { AxonUtils } from "../../../utils/axon-utils";
import { ActivatedRoute, Router } from "@angular/router";
import { GroupListsManager } from "../../../settings/grouplists/group-lists-manager";
import { GROUPLIST } from "../../../utils/constants";
import { ServerError } from "../../../utils/server-errors";

@Component({
	selector: "app-agent-blacklist",
	templateUrl: "./agent-blacklist.component.html",
	styleUrls: ["./agent-blacklist.component.scss"],
})
export class AgentBlacklistComponent extends AxonComponent implements OnInit {
	instance: AgentBlacklistComponent;
	@Input() agent: Agent;

	loggedInAgent: Agent;

	agentId: number;

	selectedRole: string;

	isLoadingResults = true;
	routeSubscription: Subscription;

	blacklistAction = "Blacklist/Unblacklist";
	blacklistMessage =
		"Flag an agent for committing fraudulent activities. Once an agent is blacklisted, their registrations will be sent for approval regardless of the registration / ID document.";

	blacklistedStatus: AgentBlacklistDto = {};

	rejectionReasons;

	selectedReason;

	/* >>> ReactiveForms valueChanges Observer */
	next(values) {}
	/* <<< ReactiveForms valueChanges Observer */

	constructor(
		public activeModal: NgbActiveModal,
		private notifier: Notifier,
		private agentService: AgentService,
		private authService: AuthService,
		public groupListsMgr: GroupListsManager
	) {
		super();

		this.instance = this;
	}

	ngOnInit(): void {
		console.log("AgentBlacklistComponent.ngOnInit(): ", this.agent);
		this.loggedInAgent = this.authService.getAuthAgent();
		this.agentId = this.agent.agentId;
		this.isLoadingResults = true;
		this.getAgentBlacklistStatus();

		this.rejectionReasons = this.groupListsMgr.getGroupList(
			GROUPLIST.IDX_REJECTION_REASON_BLACKLIST
		);

		this.selectedReason = this.rejectionReasons[0].text;
	}

	isFlagged() {
		if (!this.blacklistedStatus.flaggedByAgentId && !this.blacklistedStatus.flaggedBySystem) {
			return false;
		}
		return (
			this.blacklistedStatus.flaggedByAgentId !== 0 ||
			this.blacklistedStatus.flaggedBySystem !== 0
		);
	}

	getAgentBlacklistStatus() {
		this.isLoadingResults = true;
		this.agentService
			.getAgentBlacklistStatus(this.agentId)
			.subscribe((resp) => {
				if (resp.data) {
					this.blacklistedStatus = resp.data;
				}
				this.isLoadingResults = false;

				if (!resp.data) {
					this.blacklistAction = "Blacklist";
					this.blacklistMessage =
						"Flag an agent for committing fraudulent activities. Once an agent is blacklisted, their registrations will be sent for approval regardless of the registration / ID document.";
					return;
				}

				this.blacklistAction = "Unblacklist";
					this.blacklistMessage =
						"To unblacklist this agent, verify the details are correct and click 'Unblacklist'. Once done, their registrations will no longer require manual approval.";

			});
	}

	blacklistUnblacklistAgent() {
		if(this.isFlagged()) {
			this.unblacklistAgent();
		} else {
			this.blacklistAgent();
		}
	}

	blacklistAgent() {
		this.isLoadingResults = true;
		this.blacklistedStatus.blacklistedAgentId = this.agentId;
		this.blacklistedStatus.reasonDesc =
			this.blacklistedStatus.reason === "Other"
				? this.blacklistedStatus.reasonDesc
				: null;

		this.agentService.blacklistAgent(this.blacklistedStatus).subscribe(
			(res) => {
				if (res.success) {
					this.notifier.info("Agent Blacklisted Successfully");
					this.activeModal.close(true);
				} else {
					this.notifier.info(ServerError.formatError(res));
				}
			},
			(error) => {
				console.log(error);
				this.notifier.error("Error Blacklisting Agent");
			}
		);
	}

	unblacklistAgent() {
		this.isLoadingResults = true;
		this.agentService.unBlacklistAgent(this.agentId).subscribe(
			(res) => {
				if (res.success) {
					this.notifier.info("Agent Un-Blacklisted Successfully");
					this.activeModal.close(true);
				} else {
					this.notifier.info(ServerError.formatError(res));
				}
			},
			(error) => {
				console.log(error);
				this.notifier.error("Error Un-Blacklisting Agent");
			}
		);
	}

	ngOnDestroy() {}

	ngAfterViewInit() {}

	ngOnChanges() {}

	goBack() {}
}
